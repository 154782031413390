import(`./src/scss/themes/${ process.env.GATSBY_PBG_BRAND }.scss`);

export { wrapRootElement } from './gatsby-ssr';

/**
 * Gatsby browser API onRouteUpdate()
 *
 * Checks if the yotpo widget or the yotpoWidgetsContainer has been been initialized,
 * then initializes it if they have not.
 */
export const onRouteUpdate = () => {
	setTimeout(() => {
		if (
			typeof window?.yotpo === 'undefined' &&
			typeof window?.yotpoWidgetsContainer !== 'undefined' &&
			typeof window?.yotpoWidgetsContainer?.initWidgets !== 'undefined'
		) {
			window.yotpoWidgetsContainer.initWidgets();
		}

		if (
			typeof window.yotpo !== 'undefined' &&
			typeof window.yotpo.initWidgets !== 'undefined'
		) {
			window.yotpo.initWidgets();
		}
	});
};

//	Allows us to disable default behavior of scroll-to-top upon Gatsby's navigate() .
//	Usage:
//	navigate(pathname, {
//		state: {
//			disableScrollUpdate: true,
//		},
//	});
export const shouldUpdateScroll = ({ routerProps }) => {
	if (routerProps?.location?.state) {
		const { disableScrollUpdate } = routerProps.location.state;
		return !disableScrollUpdate;
	}

	return null;
};
