/*
//	Example Videeo LineItem Product event payload
product: {
	sku:		'SKU-123',	//	{string} item SKU
	price:		34.56,		//	{float} price of a single unit of the variant
	currency:	'USD',		//	{string} in iso_3 format
	quantity:	1,			//	{number} of the variant purchased
}
*/
function castShopifyLineItemToVideeoLineItem(shopifyLineItem) {
	const videeoLineItem = {
		sku:			shopifyLineItem.variant.sku,
		price:			parseFloat(shopifyLineItem.variant.price.amount),
		currency:		shopifyLineItem.cost.totalAmount.currencyCode || 'USD',
		quantity:		parseInt(shopifyLineItem.quantity, 10),
	};
	return videeoLineItem;
}


export function addToCartEvent(shopifyLineItems,shopifyCartLinesInput) {

	const { videeoCommand } = window || {};

	//	Make sure framework is initialized at this time
	if( typeof videeoCommand !== 'function' ){
		console.log('There is no Videeo framework initialized.');
		return false;
	}

	const addedLineItems = 	shopifyLineItems
		.map((shopifyLineItem) => {
			//	We need some information from the CartLineInput.
			//	So we need to isolate the item just added from other cart items.
			const itemJustAdded = shopifyCartLinesInput.find(shopifyCartLine => {
				return shopifyLineItem.merchandise.id === shopifyCartLine.merchandiseId;
			});

			//	We only want to emit the event for LineItems that are being added;
			//	Not all items in the cart.
			if (!itemJustAdded) return null;

			const videeoLineItem = castShopifyLineItemToVideeoLineItem(shopifyLineItem);
			videeoLineItem.quantity = itemJustAdded.quantity;	//	Quantity in Cart doesn't necessarily match quantity just added. We need to set this.

			//	Deliver data
			//	Unfortunately, the Videeo framework makes us invoke tracking on each added item separately.
			const data = { product: videeoLineItem };
			videeoCommand('addToCart', data);

			return videeoLineItem;
		})
		//	eslint-disable-next-line
		.filter(_=>_);

	console.log(`Videeo AddToCart Event: ${addedLineItems.length} items`, addedLineItems);

	return true;
}