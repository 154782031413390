import fetch from 'isomorphic-fetch';

import {
	STOREFRONT_API_URL,
	STOREFRONT_API_ACCESS_TOKEN,
	COMING_SOON_LIST_ID
} from './config';

/**
 * Fetch Shopify Storefront API data
 * @param {string} graphql query
 */
export const getShopifyData = query => {
	return fetch(
		STOREFRONT_API_URL,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-Shopify-Storefront-Access-Token': STOREFRONT_API_ACCESS_TOKEN,
			},
			body: JSON.stringify({ query }),
		}
	)
		.then(response => {
			if (response.status !== 200) {
				throw new Error('cannot fetch data');
			}
			return response;
		})
		.then(response => {
			return response.json();
		})
		.then(json => {
			if (json.errors?.length) {
				// eslint-disable-next-line
				console.error(`Storefront API key may not be set in this Product's Sales Channel`, json.errors);
			}
			return json;
		})
		.catch(error => {
			// eslint-disable-next-line
			console.error(error);
			return null;
		});
};

/**
 * Get an array of products given an array of product ids
 * @param {string} product storefrontId
 */
export const getProductsById = async(ids = []) => {
	const promiseArray = [];

	ids.forEach(id => {
		const productQuery = `
			{
				product(id: "${ id }") {
					id
					title
					handle
					productType
					createdAt
					images(first: 2) {
						nodes {
							id
							altText
							height
							originalSrc
							src
							transformedSrc(maxHeight: 1128, maxWidth: 900)
							url
							width
						}
					}
					metafields(
						identifiers: [
							{ namespace: "custom", key: "text_badge" }
							{ namespace: "custom", key: "image_badge" }
							{ namespace: "custom", key: "subtitle" }
							{ namespace: "custom", key: "short_description" }
							{ namespace: "custom", key: "benefits" }
							{ namespace: "custom", key: "yotpo_reviews" }
							{ namespace: "custom", key: "product_is_hidden" }
							{ namespace: "custom", key: "${COMING_SOON_LIST_ID}" }
							{ namespace: "custom_fields", key: "bundle_collection" }
							{ namespace: "custom_fields", key: "bundle_product_with_variants" }
							{ namespace: "custom_fields", key: "bundle_product_no_variant" }
							{ namespace: "custom_fields", key: "bundle_product_unique_variant" }
						]
					) {
						namespace
						key
						value
					}
					priceRange {
						minVariantPrice {
							currencyCode
						}
					}
					variants(first: 2) {
						nodes {
							availableForSale
							id
							compareAtPrice {
								amount
							}
							price {
								amount
							}
							title
							sku
						}
					}
					tags
				}
			}
		`;

		promiseArray.push(getShopifyData(productQuery));
	});

	const response = await Promise.all(promiseArray);

	return response
		.filter(({ data }) => {
			return data?.product;
		})
		.map(({ data }) => {
			return {
				...data.product,
			};
		});
};

/**
 * Get a Collection by its storefrontId
 * @param {string} collection storefrontId
 * @param {number} retrieve the first x products
 */
export const getCollectionById = async(id = '', first = 6) => {
	const collectionQuery = `
		{
			collection(id: "${ id }") {
				title
				products(first: ${ first }) {
					edges {
						product: node {
							id
							title
							handle
							productType
							createdAt
							images(first: 2) {
								nodes {
									id
									altText
									height
									originalSrc
									src
									transformedSrc(maxHeight: 1128, maxWidth: 900)
									url
									width
								}
							}
							metafields(
								identifiers: [
									{ namespace: "custom", key: "text_badge" }
									{ namespace: "custom", key: "image_badge" }
									{ namespace: "custom", key: "subtitle" }
									{ namespace: "custom", key: "short_description" }
									{ namespace: "custom", key: "benefits" }
									{ namespace: "custom", key: "yotpo_reviews" }
									{ namespace: "custom", key: "product_is_hidden" }
									{ namespace: "custom", key: "${COMING_SOON_LIST_ID}" }
									{ namespace: "custom_fields", key: "bundle_collection" }
									{ namespace: "custom_fields", key: "bundle_product_with_variants" }
									{ namespace: "custom_fields", key: "bundle_product_no_variant" }
									{ namespace: "custom_fields", key: "bundle_product_unique_variant" }
								]
							) {
								namespace
								key
								value
							}
							priceRange {
								minVariantPrice {
									currencyCode
								}
							}
							variants(first: 2) {
								nodes {
									availableForSale
									id
									compareAtPrice {
										amount
									}
									price {
										amount
									}
									title
									sku
								}
							}
							tags
						}
					}
				}
			}
		}
	`;

	let response = await getShopifyData(collectionQuery);

	response = response?.data?.collection?.products?.edges.map(({ product }) => {
		return {
			collection: response?.data?.collection?.title,
			...product,
		};
	});

	return response;
};

/**
 * Get a Collection's Data by its storefrontId
 * @param {string} collection storefrontId
 * @param {number} retrieve the first x products
 */
export const getCollectionDataById = async(id = '') => {
	const collectionQuery = `
		{
			collection(id: "${ id }") {
				id
				title
				handle
			}
		}
	`;

	const response = await getShopifyData(collectionQuery);

	return response?.data?.collection;
};

/**
 * Parses the bundleCollection string into an array of collections. Returns an array
 * of collections with their respective products.
 * @param {string} bundleCollection | collection string separated by pipe '|'
 * @param {number} first | the limit of collections allowed
 */
export const getBundleCollections = async(bundleCollection, first = 100) => {
	const promiseArray = [];

	if (!bundleCollection || bundleCollection === ``) { return false; }

	bundleCollection.split(/\|/).forEach(handle => {
		const collectionQuery = `
		{
			collection(handle: "${ handle }") {
				id
				title
				products(first: ${ first }) {
					edges {
						product: node {
							id
							title
							description
							productType
							variants(first: 1) {
								nodes {
									sku
								}
							}
							images(first: 2) {
								nodes {
									id
									alt: altText
									height
									originalSrc
									src
									transformedSrc(maxHeight: 1128, maxWidth: 900)
									url
									width
								}
							}
						}
					}
				}
			}
		}`;

		promiseArray.push(getShopifyData(collectionQuery));
	});

	let response = await Promise.all(promiseArray);

	response = response.map((item, index) => {
		const collection = item?.data?.collection;
		const products = collection?.products?.edges;

		return {
			id: collection?.id,
			title: collection?.title,
			products: products?.map(({product}) => {
				return {
					...product,
					id: product.id,
					label: product.title,
					sku_index: index + 1,
					sku_code: `C`,
					product_name: `${ collection?.title } - ${ product?.title }`,
					value: product.variants?.nodes[0].sku,
					metafields: product?.metafields?.nodes,
					images: product.images?.nodes.map(image => {
						return {
							...image,
						};
					}),
				};
			}),
		};
	});

	return response;
};

/**
 * Parses the handleList string with a list of product handles. Returns an array of products.
 * @param {string} handleList | collection string separated by pipe '|'
 */
export const getProductsByHandleList = async(handleList, variantType) => {
	const promiseArray = [];
	let parsedHandles = [];
	let preSelectedVariants = [];
	let skuCode = ``;

	if (!handleList || handleList === `` || !variantType) { return false; }

	if (variantType === `unique-variant`) {
		const parsedJSON = JSON.parse(handleList);

		preSelectedVariants = parsedJSON.map(item => { return item.variant_id; });
		parsedHandles = parsedJSON.map(item => { return item.product; });
		skuCode = `SV`;
	} else if (variantType === `no-variant`) {
		parsedHandles = handleList.split(/\|/);
		skuCode = `NV`;
	} else if (variantType === `variants`) {
		parsedHandles = handleList.split(/\|/);
		skuCode = `V`;
	} else {
		return false;
	}

	parsedHandles.forEach(handle => {
		const productQuery = `
			{
				product: productByHandle(handle: "${ handle }") {
					id
					title
					description
					handle
					productType
					createdAt
					media(first: 100) {
						edges {
							node {
								...on MediaImage {
									altText: alt
									mediaContentType
									image {
										url
									}
								}
							}
						}
					}
					options {
						name
						values
					}
					images(first: 2) {
						nodes {
							id
							altText
							height
							originalSrc
							src
							transformedSrc(maxHeight: 1128, maxWidth: 900)
							url
							width
						}
					}
					metafields(
						identifiers: [
							{ namespace: "custom", key: "text_badge" }
							{ namespace: "custom", key: "image_badge" }
							{ namespace: "custom", key: "subtitle" }
							{ namespace: "custom", key: "short_description" }
							{ namespace: "custom", key: "benefits" }
							{ namespace: "custom", key: "yotpo_reviews" }
							{ namespace: "custom", key: "product_is_hidden" }
							{ namespace: "custom_fields", key: "bundle_collection" }
							{ namespace: "custom_fields", key: "bundle_product_with_variants" }
							{ namespace: "custom_fields", key: "bundle_product_no_variant" }
							{ namespace: "custom_fields", key: "bundle_product_unique_variant" }
						]
					) {
						namespace
						key
						value
					}
					priceRange {
						minVariantPrice {
							currencyCode
						}
					}
					variants(first: 10) {
						nodes {
							availableForSale
							id
							compareAtPrice {
								amount
							}
							price {
								amount
							}
							title
							sku
							selectedOptions {
								name
								value
							}
						}
					}
					tags
				}
			}
		`;

		promiseArray.push(getShopifyData(productQuery));
	});

	let response = await Promise.all(promiseArray);

	response = response
		.filter(({ data }) => {
			return data?.product;
		}).map(({ data }, index) => {
			return {
				...data.product,
				value: data.product.variants.nodes[0].sku,
				metafields: data.product?.metafields?.nodes,
				variant_type: variantType,
				variants: data.product?.variants?.nodes.map(_variant => {
					return {
						..._variant,
						product_name: _variant.title !== `Default Title`
							? `${ data.product.title } - ${ _variant.title }`
							: data.product.title,
						sku_index: index + 1,
						sku_code: skuCode,
						value: _variant.sku,
					};
				}),
				variant_id: variantType === `unique-variant` && preSelectedVariants[index],
				media: data.product?.media?.edges?.map(_media => {
					return { ..._media?.node };
				}),
				images: data.product.images?.nodes.map(image => {
					return {
						...image,
					};
				}),
			};
		});

	return response;
};

/**
 * Get a Media File by its storefrontId
 * @param {string} Shopify media file id
 */
export const getMetaFieldImage = async(
	productShopifyId,
	metafieldKey,
	metafieldNamespace
) => {
	const metafieldImageQuery = `
		{
			product(id: "${ productShopifyId }") {
				title
				metafield(key: "${ metafieldKey }", namespace: "${ metafieldNamespace }") {
					reference {
						... on MediaImage {
							image {
								altText
								height
								width
								originalSrc
								src
								transformedSrc(maxHeight: 100, maxWidth: 100)
							}
						}
					}
				}
			}
		}
	`;

	const response = await getShopifyData(metafieldImageQuery);
	// console.log(response?.data?.product?.metafield?.reference?.image);
	return response?.data?.product?.metafield?.reference?.image || null;
};

/**
 * Determine if url is an external url
 * @param {string} url.
 */
export const isExternalUrl = url => {
	let isExternal = false;

	if (url && (url.includes('//') || url.match(/((^(mailto|tel|sms|mms):)|www.)/) || url.includes('#'))) {
		isExternal = true;
	}

	return isExternal;
};

/**
 * Return embed url and type given a YT or Vimeo url
 * @param {string} url.
 */
export const getVideoEmbedData = url => {
	if (!url) return false;

	const pattern = /(\/\/.+\/)(.+v=)?([a-zA-Z0-9-]+)($|\?.+)/;
	const matches = url.match(pattern);

	if (!matches) return false;

	const videoId = matches[3]; // Video ID is 3rd capturing group.
	let src;
	let type;

	if (url.indexOf('youtu') !== -1) {
		type = `youtube`;
		src = `https://www.youtube.com/embed/${ videoId }?autoplay=1`;
	} else if (url.indexOf('vimeo') !== -1) {
		type = `vimeo`;
		src = `https://player.vimeo.com/video/${ videoId }`;
	} else {
		return false;
	}

	return {
		type,
		src,
	};
};

/**
 * Return parsed json or false
 * @param {string} JSON.
 */

export const parseJson = json => {
	let output = false;

	if (json) {
		try {
			output = JSON.parse(json);
			return output;
		} catch {
			// eslint-disable-next-line
			console.error('error parsing related products');
		}
	}

	return output;
};

/**
 * Return decoded base64 or the given string
 * @param {string}
 */
export const decodeBase64 = string => {
	let output = string;

	try {
		output = atob(string);
	} catch {
		//	Do nothing
	}

	return output;
}

/**
 * Return Shopify metafield given a key
 * @param {array} metafields
 * @param {string} key
 */

export const getMetafield = (metafields = [], key = '') => {
	const output = metafields.find(field => {
		return field.key === key;
	});

	return output;
};

/**
 * A helper method to convert a breakpoint exported from scss
 * into a proper media query string
 * @param {string} query
 * @returns {string} A formatted media-query string
 */
export const formatMediaQuery = breakpoint => {
	/**
	 * Replacing all double quotes here. We are doing it because
	 * currently we export our breakpoints from the scss file
	 * which wraps the value in a double quote
	 */
	let mediaQuery = breakpoint.replace(/["]+/g, '');

	/**
	 * If user forgot to supply parenthesis, wrap the query string with them
	 */
	if (!mediaQuery.startsWith('(')) {
		mediaQuery = `(${ mediaQuery })`;
	}
	return mediaQuery;
};

/**
 * A helper method to grab the relative path
 * from the menu item URls Shopify provides
 * @param {string} link
 * @returns {string} A formatted media-query string
 */
export const getMenuItemRelativePath = link => {
	const relativePath = link.split('/').pop();

	return relativePath;
};

/*
* Create a map of all metafiles that exist
* on the product object for easy access
* @param {array} Shopify metafields
*/
export const createMetaFieldsMap = (metafields = []) => {
	const output = {};

	metafields.forEach(({namespace, key, value}) => {
		if (!output[namespace]) {
			output[namespace] = {};
		}

		output[namespace][key] = value;
	});

	return output;
};

/*
* Create a map of all customAttributes that exist
* on a checkout lineItem object for easy access
* @param {array} customAttributes
*/
export const createCustomAttributesMap = (attributes = []) => {
	const output = {};

	attributes.forEach(({key, value}) => {
		output[key] = value;
	});

	return output;
};

export const getRichTextJSON = text => {
	const document = {
		nodeType: 'document',
		content: [
			{
				nodeType: 'paragraph',
				content: [
					{
						nodeType: 'text',
						value: text,
						marks: [],
					},

				],
			},
		],
	};
	return JSON.stringify(document);
};

/**
 * Used by Klaviyo product analytics.
 * @returns {object} methods for ecommerce event tracking.
 */
export const learnqPush = () => {
	// eslint-disable-next-line no-underscore-dangle
	const learnq = window?._learnq || [];

	const addToCart = (data = {}) => {
		if (!data?.cost || !data?.lineItems || !data?.checkoutUrl) {
			throw new Error('addToCart requires cost, lineItems, and checkoutUrl');
		}

		const { cost, lineItems, checkoutUrl } = data;
		const [addedItem] = lineItems;

		const $value = parseFloat(cost.totalAmount.amount);
		const AddedItemProductName = addedItem.variant.product.title;
		const AddedItemProductID = addedItem.variant.product.id;
		const AddedItemSKU = addedItem.variant.sku;
		const AddedItemCategories = [addedItem.variant.product.productType];
		const AddedItemImageURL = addedItem.variant.image.url;
		const AddedItemURL = `${process.env.GATSBY_SITE_URL}/products/${addedItem.variant.product.handle}`;
		const AddedItemPrice = parseFloat(addedItem.variant.price.amount);
		const AddedItemQuantity = parseInt(addedItem.quantity, 10);
		const CheckoutURL = checkoutUrl;

		const ItemNames = lineItems.map(item => {
			return item.variant.product.title;
		})

		const Items = lineItems.map(item => {
			return {
				ProductID: item.variant.product.id,
				SKU: item.variant.sku,
				ProductName: item.variant.product.title,
				Quantity: parseInt(item.quantity, 10),
				ItemPrice: parseFloat(item.variant.price.amount),
				RowTotal: parseFloat(item.variant.price.amount) * parseInt(item.quantity, 10),
				ProductURL: `${process.env.GATSBY_SITE_URL}/products/${item.variant.product.handle}`,
				ImageURL: item.variant.image.url,
				ProductCategories: [item.variant.product.productType],
			};
		});

		const payload = {
			$value,
			AddedItemProductName,
			AddedItemProductID,
			AddedItemSKU,
			AddedItemCategories,
			AddedItemImageURL,
			AddedItemURL,
			AddedItemPrice,
			AddedItemQuantity,
			CheckoutURL,
			ItemNames,
			Items,
		}
		learnq.push(['track', 'Added to Cart', payload]);
	};

	const viewItem = (data = {}) => {
		const {product, variant} = data;
		const payload = {
			ProductName: product?.title,
			ProductID: product?.shopifyId || product?.id,
			SKU: variant?.sku,
			Categories: [product?.productType],
			ImageURL: product?.featuredImage?.src,
			URL: `${process.env.GATSBY_SITE_URL}/products/${product?.handle}`,
			Brand: process.env.GATSBY_PBG_BRAND,
			Price: parseFloat(variant?.price),
			CompareAtPrice: parseFloat(variant?.compareAtPrice),
		}
		learnq.push(['track', 'Viewed Product', payload]);
	};

	return {
		addToCart,
		viewItem,
	}
}

/**
 * Used by Google Tag Manager to pass product data to Google Analytics.
 * @returns {object} methods for ecommerce event tracking.
 */
export const dataLayerPush = () => {
	const dataLayer = window?.dataLayer || [];

	const dataLayerItem = (data = {}) => {
		const {product, variant, quantity} = data;
		const hasVariants = product?.variants?.length > 1 || variant?.title !== 'Default Title';
		const item = {
			product_id: product?.shopifyId || product?.id,
			variant_id: hasVariants ? (variant?.shopifyId || variant?.id) : null,
			item_name: product?.title,
			item_category: product?.productType,
			item_id: variant?.sku,
			item_variant: hasVariants ? variant?.title : null,
			price: parseFloat(variant?.price),
			quantity: parseInt(quantity, 10),
		}
		return item;
	};

	const addToCart = (data = {}) => {
		dataLayer.push({
			event: 'add_to_cart',
			ecommerce: { items: [dataLayerItem(data)] },
		});
	};

	const removeFromCart = (data = {}) => {
		dataLayer.push({
			event: 'remove_from_cart',
			ecommerce: { items: [dataLayerItem(data)] },
		});
	};

	const viewItem = (data = {}) => {
		learnqPush().viewItem({
			product: data.product,
			variant: data.variant
		});
		dataLayer.push({
			event: 'view_item',
			ecommerce: { items: [dataLayerItem(data)] },
		});
	};

	const viewItemList = (data = {}) => {
		dataLayer.push({
			event: 'view_item_list',
			ecommerce: {
				items: data.products
					.map(dataLayerItem)
					.filter(item => {
						return item.product_id // Filter out promo cards
					})
			},
		});
	};

	const beginCheckout = (data = {}) => {
		dataLayer.push({
			event: 'begin_checkout',
			ecommerce: {
				items: data.items.map(dataLayerItem)
			},
		});
	};

	const siteSearch = (data = {}) => {
		dataLayer.push({
			event: 'site_search',
			search_term: data.searchQuery,
		});
	};

	const signUp = (data = {}) => {
		dataLayer.push({
			event: 'sign_up',
			type: data.type,
			user_email: data.email,
		})
	}

	return {
		addToCart,
		removeFromCart,
		viewItem,
		viewItemList,
		beginCheckout,
		siteSearch,
		signUp,
	};
};

/*
* Returns the first image from Shopify Media array
* @param {array} media
*/
export const getMetaImageFromShopifyMedia = media => {
	return media.find(mediaItem => {
		return mediaItem.mediaContentType === 'IMAGE';
	});
};

export const tryAtob = s => {
	try {
		return atob(s);
	} catch {
		return s;
	}
};

export const tryBtoa = s => {
	try {
		return btoa(s);
	} catch {
		return s;
	}
};


//	@description
//		Determine whether the given `input` is iterable.
//	@return	{Boolean}
export const isIterable = input => {
	if (input === null || input === undefined) {
		return false;
	}

	return typeof input[Symbol.iterator] === 'function';
}
