function castShopifyLineItemToNorthbeamLineItem(shopifyLineItem) {
	const northbeamLineItem = {
		sku:			shopifyLineItem.variant.sku,
		price:			parseFloat(shopifyLineItem.variant.price.amount),
		currency:		shopifyLineItem.cost.totalAmount.currencyCode || 'USD',
		quantity:		parseInt(shopifyLineItem.quantity, 10),
	};
	return northbeamLineItem;
}

export function addToCartEvent(shopifyLineItems,shopifyCartLinesInput) {

	const { Northbeam } = window || {};

	//	Make sure framework is initialized at this time
	if( typeof Northbeam?.fireCustomGoal !== 'function' ){
		console.log('There is no Northbeam framework initialized.');
		return false;
	}

	const addedLineItems = 	shopifyLineItems
		.map((shopifyLineItem) => {
			//	We need some information from the CartLineInput.
			//	So we need to isolate the item just added from other cart items.
			const itemJustAdded = shopifyCartLinesInput.find(shopifyCartLine => {
				return shopifyLineItem.merchandise.id === shopifyCartLine.merchandiseId;
			});

			//	We only want to emit the event for LineItems that are being added;
			//	Not all items in the cart.
			if (!itemJustAdded) return null;

			const northbeamLineItem = castShopifyLineItemToNorthbeamLineItem(shopifyLineItem);
			northbeamLineItem.quantity = itemJustAdded.quantity;	//	Quantity in Cart doesn't necessarily match quantity just added. We need to set this.

			//	Deliver data
			const data = northbeamLineItem;
			window.Northbeam.fireCustomGoal('add_to_cart', data);

			return northbeamLineItem;
		})
		//	eslint-disable-next-line
		.filter(_=>_);

	console.log(`Northbeam 'add_to_cart' Event: ${addedLineItems.length} items`, addedLineItems);

	return true;
}