import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

const localeCode = process.env.GATSBY_LOCALE_CODE || 'en-US';

i18n
	.use(resourcesToBackend((language, namespace, callback) => {
		import(`../locales/${ language }/${ namespace }.json`)
			.then(resources => {
				callback(null, resources);
			})
			.catch(error => {
				callback(error, null);
			});
	}))
	.init({
		fallbackLng: localeCode,
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
	});

export default i18n;
