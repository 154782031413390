import React from 'react';
import classNames from 'classnames';
import renderIcon from '../../utils/renderIcon';

const ns = `numeric-input`;

const NumericInput = ({
	onIncrement,
	onDecrement,
	className,
	disabled,
	...props
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<button
				disabled={disabled}
				className={`${ ns }__decrement`}
				aria-label={'Decrement'}
				onClick={onDecrement}
			>
				<span className={`visually-hidden`}>-</span>
				<span aria-hidden>{renderIcon('minus-sm')}</span>
			</button>
			<input
				disabled={disabled}
				type={'numeric'}
				className={[`${ ns }__input`, className].join(' ')}
				{...props}
			/>
			<button
				disabled={disabled}
				className={`${ ns }__increment`}
				aria-label={'Increment'}
				onClick={onIncrement}
			>
				<span className={`visually-hidden`}>+</span>
				<span aria-hidden>{renderIcon('plus-sm')}</span>
			</button>
		</div>
	);
};

export default NumericInput;
