import * as React from 'react';

function SearchIcon(props) {
	return (
		<svg
			width={'22'}
			height={'22'}
			viewBox={'0 0 22 22'}
			fill={'none'}
			xmlns={'http://www.w3.org/2000/svg'}
			{...props}
		>
			<circle
				cx={'9'}
				cy={'9'}
				r={'6.5'}
				stroke={'currentColor'}
			/>
			<line
				x1={'13.7071'}
				y1={'14'}
				x2={'20'}
				y2={'20.2929'}
				stroke={'currentColor'}
				strokeLinecap={'square'}
			/>
		</svg>
	);
}

export default SearchIcon;
