/* eslint-disable */

import * as React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { navigate } from 'gatsby-link';
import slugify from '@sindresorhus/slugify';

import { dataLayerPush } from '../utils';

const algoliaClient = algoliasearch(
	process.env.GATSBY_ALGOLIA_APP_ID,
	process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const searchClient = {
	search(requests) {
		if (requests[0].params.query === "") {
			return [];
		}

		return algoliaClient.search(requests);
	},
};

const defaultValues = {
	searchClient,
	isSearchOpen: false,
	searchInputValue: '',
	searchState: null,
	query: `magnetic`,
	results: [],
	defaultResults: [],
	handleSearchToggle: () => {},
	handleSearchChange: () => {},
	handleSearchClose: () => {},
	handleSearchSuggestionClick: () => {},
	handleSearchSubmit: () => {},
	handleSearchResults: () => {},
};

export const SearchContext = React.createContext(defaultValues);

export const SearchProvider = ({ children }) => {
	const [searchInputValue, setSearchInputValue] = React.useState(null);
	const [searchState, setSearchState] = React.useState(null);
	const [isSearchOpen, setIsSearchOpen] = React.useState(false);
	const [defaultResults, setDefaultResults] = React.useState([]);
	const results = React.useRef([]);
	const query = React.useRef(null);

	const handleSearchToggle = e => {
		e.preventDefault();

		if (isSearchOpen && searchInputValue) {
			return handleSearchSubmit(e, searchInputValue)
		}

		return setIsSearchOpen(!isSearchOpen);
	};

	const handleSearchClose = React.useCallback(() => {
		if (!isSearchOpen) return;

		setIsSearchOpen(false);
	}, [isSearchOpen]);

	const handleSearchChange = (e, value) => {
		if ((value && value.length > 2) || e.target.value.length > 2 ) {
			setSearchState(value || e.target.value);
		} else {
			results.current = [];
		}

		if (!value && !e.target.value) {
			setSearchState(null);
		}

		return setSearchInputValue(e.target.value);
	};

	const handleSearchSuggestionClick = (e, suggestion) => {
		const inputElem = document.querySelector(`.search__search-form input`);

		e.preventDefault();

		if (inputElem) {
			inputElem.focus();
		}

		return (
			setSearchState(suggestion),
			setSearchInputValue(suggestion)
		);
	};

	const handleSearchSubmit = (e, searchQuery) => {
		e.preventDefault();

		if (!searchInputValue || (searchInputValue?.length && searchInputValue.length < 3)) {
			results.current = [];
		}

		query.current = searchQuery;

		setSearchState(null);
		handleSearchClose();
		navigate(`/search-results?q=${ slugify(searchQuery) }`);
		dataLayerPush().siteSearch({ searchQuery });
	};

	const handleSearchResults = (searchResults) => {
		if (searchResults?.hits.length > 0) {
			// Filter out any hit without image
			results.current = searchResults.hits.filter(_hit => {
				return _hit.featuredImage;
			});
		}
		else {
			results.current = [];
		}
	};

	return (
		<SearchContext.Provider
			value={{
				...defaultValues,
				isSearchOpen,
				searchInputValue,
				searchState,
				results,
				query,
				defaultResults,
				setDefaultResults,
				handleSearchToggle,
				handleSearchChange,
				handleSearchClose,
				handleSearchSubmit,
				handleSearchSuggestionClick,
				handleSearchResults,
			}}
		>
			{children}
		</SearchContext.Provider>
	);
};
