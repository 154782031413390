import { useStaticQuery, graphql } from 'gatsby';

const useHeaderQuery = () => {
	const data = useStaticQuery(graphql`
		query {
            contentfulHeader {
                preHeaderBanner {
                    text
                    url
                }
                preHeaderBannerColor {
                    value
                    name
                }
                promoBanner {
                    text
                    url
                }
            }
		}
	`);

	return data;
};

export default useHeaderQuery;
