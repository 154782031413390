import * as React from 'react';

function MinusSm(props) {
	return (
		<svg
			width={'13'}
			height={'1'}
			viewBox={'0 0 13 1'}
			fill={'none'}
			xmlns={'http://www.w3.org/2000/svg'}
			{...props}
		>
			<path
				stroke={'#000'}
				strokeLinecap={'round'}
				d={'M11.555.5H1.427'}
			/>
		</svg>
	);
}

export default MinusSm;
