import * as React from 'react';

function Close(props) {
	return (
		<svg
			width={`20`}
			height={`20`}
			viewBox={`0 0 20 20`}
			fill={`none`}
			xmlns={`http://www.w3.org/2000/svg`}
			{...props}
		>
			<path
				d={`m1 1 18.5 18.5m0-18.5-9.25 9.25L1 19.5`}
				stroke={`currentColor`}
			/>
		</svg>
	);
}

export default Close;
