import { useStaticQuery, graphql } from 'gatsby';

const useFooterQuery = () => {
	const {
		contentfulGlobalSettings,
		regionLinks,
		contentfulFooter,
	} = useStaticQuery(graphql`
        query {
            regionLinks: allMenus(filter: {location: {eq: "regions"}}) {
                nodes {
                    title
                    url
                }
            }
            contentfulFooter {
                newsletterListId
                column1Links {
                    text
                    url
                }
                column2Links {
                    text
                    url
                }
                column1Title
                column2Title
                copyright
                socialLinks {
                    altText
                    image {
                        description  
                        gatsbyImageData(layout: FIXED,height:25, width:25)
                    }
                    link
                }
                mailingListFormTitle
            }
            contentfulGlobalSettings {
                logo {
                    altText
                    link
                    image {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    `);

	return {
		regionLinks,
		contentfulFooter,
		contentfulGlobalSettings,
	};
};

export default useFooterQuery;
