import * as React from 'react';

function CheveronDown(props) {
	return (
		<svg
			width={'17'}
			height={'9'}
			viewBox={'0 0 17 9'}
			fill={'none'}
			xmlns={'http://www.w3.org/2000/svg'}
			{...props}
		>
			<line
				x1={'0.392616'}
				y1={'0.583947'}
				x2={'8.39262'}
				y2={'8.58395'}
				stroke={'currentColor'}
			/>
			<line
				y1={'-0.5'}
				x2={'11.3137'}
				y2={'-0.5'}
				transform={
					'matrix(-0.707107 0.707107 0.707107 0.707107 16.0391 0.9375)'
				}
				stroke={'currentColor'}
			/>
		</svg>
	);
}

export default CheveronDown;
