import React from 'react';
import classNames from 'classnames';

import { formatPrice } from '../../utils/format-price';

const ns = `price`;

const Price = ({
	compareAtPrice,
	currencyCode,
	price,
	decimalPlaces,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const parsedPrice = parseFloat(price);
	const parsedCompareAtPrice = parseFloat(compareAtPrice);

	/*
	* Figure out the number of decimal places to be displayed on the amount
	* @param {number} price
	*/

	const getDecimalPlaces = p => {
		return decimalPlaces || (p % 1 === 0 ? 0 : 2);
	};

	if (parsedPrice.isNaN || parsedCompareAtPrice.isNaN) return null;

	//	To avoid Intl.NumberFormat error: "Currency code is required with currency style."
	if (!currencyCode) return null;

	return (
		<div className={rootClassnames}>
			{(parsedCompareAtPrice > parsedPrice) && (
				<s className={`${ ns }__compare-at`}>
					{formatPrice(
						currencyCode,
						parsedCompareAtPrice,
						getDecimalPlaces(parsedCompareAtPrice)
					)}
				</s>
			)}
			<span className={`${ ns }__actual`}>
				{formatPrice(
					currencyCode,
					parsedPrice,
					getDecimalPlaces(parsedPrice)
				)}
			</span>
		</div>
	);
};

export default Price;
