import * as React from 'react';

function Checkbox({
	brand,
	...props
}) {
	return (
		<svg width={'21'} height={'22'} viewBox={'0 0 21 22'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
			<circle cx={'10.4997'} cy={'11.4235'} r={'7.26923'} fill={'#000'} />
			<circle cx={'10.5'} cy={'11.4229'} r={'9.69231'} stroke={'#000'} strokeWidth={'1.61538'} />
		</svg>
	);
}

export default Checkbox;
