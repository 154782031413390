import * as React from 'react';

function Checkbox({
	brand,
	...props
}) {
	return (
		brand === 'babe-original' ? (
			<svg
				width={'15'}
				height={'15'}
				viewBox={'0 0 15 15'}
				fill={'none'}
				xmlns={'http://www.w3.org/2000/svg'}
			>
				<rect
					x={'.5'}
					y={'.5'}
					width={'14'}
					height={'14'}
					rx={'.5'}
					fill={'#000'}
					stroke={'#000'}
				/>
				<path
					d={'m4 4 7 7m0-7-7 7'}
					stroke={'#fff'}
					strokeWidth={'2'}
				/>
			</svg>
		) : (
			<svg
				width={'13'}
				height={'13'}
				viewBox={'0 0 13 13'}
				fill={'none'}
				xmlns={'http://www.w3.org/2000/svg'}
				{...props}
			>
				<rect
					x={'.5'}
					y={'.5'}
					width={'12'}
					height={'12'}
					rx={'.5'}
					stroke={'#000'}
				/>
				<path
					d={'m1 1 11 11m0-11L1 12'}
					stroke={'#000'}
				/>
			</svg>
		)
	);
}

export default Checkbox;
