import React from 'react';

const ArrowNav = ({
	brand,
	...props
}) => {
	return (
		brand === 'babe-original' ? (
			<svg width={'12'} height={'19'} viewBox={'0 0 12 19'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
				<path d={'m2.5 2.5 7 6.75-7 6.75'} stroke={'#000'} strokeWidth={'3'} strokeLinecap={'square'} />
			</svg>
		) : (
			<svg width={'15'} height={'8'} viewBox={'0 0 15 8'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
				<path d={'M14.2335 4.35355C14.4288 4.15829 14.4288 3.84171 14.2335 3.64645L11.0515 0.464466C10.8563 0.269204 10.5397 0.269204 10.3444 0.464466C10.1491 0.659728 10.1491 0.976311 10.3444 1.17157L13.1728 4L10.3444 6.82843C10.1491 7.02369 10.1491 7.34027 10.3444 7.53553C10.5397 7.7308 10.8563 7.7308 11.0515 7.53553L14.2335 4.35355ZM0.439941 4.5H13.8799V3.5H0.439941V4.5Z'} fill={'#221E1F'} />
			</svg>
		)
	);
};
export default ArrowNav;
