import { useStaticQuery, graphql } from 'gatsby';

const useNavigationQuery = () => {
	const data = useStaticQuery(graphql`
    query {
      contentfulHeader {
        menuLinksDesktop {
          ... on ContentfulElementLink {
            typename: __typename
            contentTitle
            text
            url
          }
          ... on ContentfulElementDropdownLink {
            typename: __typename
            image {
              gatsbyImageData(width: 360, height: 360)
            }
            label
            contentTitle
            column1Title
            column1Links {
              text
              url
            }
            column2Title
            column2Links {
              text
              url
            }
            column3Title
            column3Links {
              text
              url
            }
            column4Title
            column4Links {
              text
              url
            }
          }
        }
        menuLinksMobile {
          ... on ContentfulElementLink {
            typename: __typename
            contentTitle
            text
            url
          }
          ... on ContentfulElementDropdownLink {
            typename: __typename
            label
            contentTitle
            column1Title
            column1Links {
              text
              url
            }
            column2Title
            column2Links {
              text
              url
            }
            column3Title
            column3Links {
              text
              url
            }
            column4Title
            column4Links {
              text
              url
            }
          }
        }
      }
      contentfulGlobalSettings {
        logo {
          altText
          link
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
				searchSuggestionsPromotedItems
        searchSuggestionsTitle
        searchSuggestionsList
      }
    }
  `);

	return data;
};

export default useNavigationQuery;
