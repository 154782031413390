import React from 'react';

import Account from '../assets/icons/Account';
import Arrow from '../assets/icons/Arrow';
import ArrowInCircle from '../assets/icons/ArrowInCircle';
import ArrowNav from '../assets/icons/ArrowNav';
import Checkbox from '../assets/icons/Checkbox';
import CheveronDown from '../assets/icons/CheveronDown';
import Close from '../assets/icons/Close';
import Filter from '../assets/icons/Filter';
import Info from '../assets/icons/Info';
import Minus from '../assets/icons/Minus';
import MinusSm from '../assets/icons/MinusSm';
import Plus from '../assets/icons/Plus';
import PlusSm from '../assets/icons/PlusSm';
import Radio from '../assets/icons/Radio';
import Search from '../assets/icons/Search';
import ShoppingBag from '../assets/icons/ShoppingBag';
import VideoPlay from '../assets/icons/VideoPlay';

const brand = process.env.GATSBY_PBG_BRAND;

const renderIcon = icon => {
	if (!icon) return null;

	switch (icon) {
	case 'account':
		return <Account />;
	case 'arrow':
		return <Arrow />;
	case 'arrow-nav':
		return <ArrowNav brand={brand} />;
	case 'arrow-in-circle':
		return <ArrowInCircle brand={brand} />;
	case 'checkbox':
		return <Checkbox brand={brand} />;
	case 'cheveron-down':
		return <CheveronDown />;
	case 'close':
		return <Close />;
	case 'filter':
		return <Filter brand={brand} />;
	case 'info':
		return <Info brand={brand} />;
	case 'minus':
		return <Minus />;
	case 'minus-sm':
		return <MinusSm />;
	case 'plus':
		return <Plus />;
	case 'plus-sm':
		return <PlusSm />;
	case 'radio':
		return <Radio brand={brand} />;
	case 'search':
		return <Search />;
	case 'shopping-bag':
		return <ShoppingBag />;
	case 'video-play':
		return <VideoPlay brand={brand} />;
	default:
		return null;
	}
};

export default renderIcon;
