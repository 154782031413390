import * as React from 'react';

function Filter({
	brand,
	...props
}) {
	return (
		brand === 'babe-original' ? (
			<svg
				width={'17'}
				height={'10'}
				viewBox={'0 0 17 10'}
				fill={'none'}
				xmlns={'http://www.w3.org/2000/svg'}
			>
				<path
					fillRule={'evenodd'}
					clipRule={'evenodd'}
					d={'M0 3h17V1H0v2Zm4 6h13V7H4V6H2v1H0v2h2v1h2V9Z'}
					fill={'#000'}
				/>
				<path
					d={'M13 0h2v4h-2V0Z'}
					fill={'#000'}
				/>
			</svg>
		) : (
			<svg
				width={'20'}
				height={'12'}
				viewBox={'0 0 20 12'}
				fill={'none'}
				xmlns={'http://www.w3.org/2000/svg'}
				{...props}
			>
				<path
					fillRule={'evenodd'}
					clipRule={'evenodd'}
					d={'M0 0h3v1h17v1H3v1H0V0Zm17 11H0v-1h17V9h3v3h-3v-1Z'}
					fill={'currentColor'}
				/>
			</svg>
		)
	);
}

export default Filter;
