import React from 'react';

import DefaultLayout from '../DefaultLayout';

const Layout = ({ children, location }) => {
	return (
		<DefaultLayout location={location}>{ children }</DefaultLayout>
	);
};

export default Layout;
