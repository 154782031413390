import { useStaticQuery, graphql } from 'gatsby';

const useAddToCartQuery = () => {
	const data = useStaticQuery(graphql`
		query globalSettingsEntryQuery {
      contentfulGlobalSettings {
        buyXGetYDiscounts {
          productToBuy
          variantToBuy
          freeVariantToAdd
        }
      }
		}
	`);

	return data;
};

export default useAddToCartQuery;
