/* eslint-disable arrow-body-style */
/* eslint-disable prefer-template */

import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import AddToCart from '../AddToCart';
import { dataLayerPush } from '../../utils';
import Link from '../Link';
import Price from '../Price';

const OptionGroup = (props) => {
	const { option, onChange, selectedValue = null } = props;
	const label = 'Select ' + option.name.toLowerCase();

	const handleChange = (event) => {
		const { value } = event.target;
		const { position } = option;

		if (typeof onChange === 'function' && value !== selectedValue) {
			onChange({ position, value });
		}
	};

	return (
		<select id={option.id} onChange={handleChange}>
			<option value={null}>{label}</option>
			{option?.values?.map((value, index) => {
				return (
					// eslint-disable-next-line react/no-array-index-key
					<option key={`option-${index}-value`} value={value}>
						{value}
					</option>
				);
			})}
		</select>
	);
};

const Options = (props) => {
	const { product, onChange } = props;
	const [selectedOptions, setSelectedOptions] = useState(
		new Array(4).fill(null)
	);

	// Filter out any option values that have a corresponding variant that is out of stock
	const filteredOptions = product?.options?.map((option) => {
		return {
			...option,
			values: option?.values?.filter((value) => {
				return (
					product?.variants?.find((variant) => variant?.title === value)
						?.inventoryQuantity >= 0
				);
			}),
		};
	});

	const handleChange = (change) => {
		const { position, value } = change;
		const nextSelectedOptions = [...selectedOptions];

		nextSelectedOptions[position] = value;

		setSelectedOptions(nextSelectedOptions);

		if (typeof onChange === 'function') {
			onChange(nextSelectedOptions);
		}
	};

	return filteredOptions?.map((option) => {
		return (
			<OptionGroup
				key={option.id}
				option={option}
				onChange={handleChange}
				selectedValue={selectedOptions[option.position]}
			/>
		);
	});
};

const RecommendationsListItem = (props) => {
	const { item, ns: _ns } = props;
	const { adminGraphqlApiId, handle, title, variants, image, options } = item;
	const isAvailable =
		item?.variants?.length !== 0 && item?.variants?.[0]?.inventoryQuantity >= 0;
	const hasOptions =
		options.length > 1 ||
		(options[0].values.length > 1 &&
			options[0].values.filter(
				(value) =>
					item?.variants?.find((variant) => variant?.title === value)
						?.inventoryQuantity >= 0
			)?.length);
	const defaultVariant = !hasOptions ? variants[0] : null;
	const gImage = {
		width: image?.width,
		height: image?.height,
		layout: 'constrained',
		images: {
			sources: [],
			fallback: {
				src: image?.src,
			},
		},
	};

	const [selectedVariant, setSelectedVariant] = useState(defaultVariant);

	const ns = `${_ns}__item`;

	const handleChange = (selectedValues) => {
		const [_, v1, v2, v3] = selectedValues.map((v) => v || null);

		const variant = variants.find((v) => {
			const { option1, option2, option3 } = v;

			return v1 === option1 && v2 === option2 && v3 === option3;
		});

		setSelectedVariant(variant);
	};

	const handleAddToCart = () => {
		dataLayerPush().addToCart({
			product: item,
			variant: selectedVariant,
			quantity: 1,
		});
	};

	if (!handle || !isAvailable) return null;

	return (
		<li className={`${ns}`}>
			<div className={`${ns}-content`}>
				<Link to={handle && `/products/${handle}`}>
					<GatsbyImage
						key={image?.src}
						alt={image?.alt || title}
						image={gImage}
					/>
					<div className={`${ns}-text`}>
						{title && <p>{title}</p>}
						{(selectedVariant?.price || variants?.[0]?.price) && (
							<Price
								className={`${ns}-price`}
								currencyCode={'USD'}
								price={selectedVariant?.price || variants?.[0]?.price}
								compareAtPrice={
									selectedVariant?.compareAtPrice ||
									variants?.[0]?.compareAtPrice
								}
							/>
						)}
					</div>
				</Link>
			</div>

			<div className={`${ns}-control`}>
				<div className={`${ns}-cart`}>
					<AddToCart
						disableNotify
						available={!!selectedVariant?.adminGraphqlApiId}
						addText={'Add'}
						oosText={item.options[0].name}
						loadingText={'Adding'}
						quantity={1}
						variantId={selectedVariant?.adminGraphqlApiId}
						gqlVariantId={selectedVariant?.adminGraphqlApiId}
						gqlProductId={adminGraphqlApiId}
						onAddToCart={handleAddToCart}
					/>
				</div>
				<div className={`${ns}-options`}>
					{hasOptions && <Options product={item} onChange={handleChange} />}
				</div>
			</div>

			<div className={`${ns}-options ${ns}-options--condensed`}>
				{hasOptions && <Options product={item} onChange={handleChange} />}
			</div>
		</li>
	);
};

const RecommendationsList = (props) => {
	const { items, ns } = props;

	return (
		<ul className={`${ns}__items`}>
			{items?.map((item) => {
				return (
					<React.Fragment key={item.adminGraphqlApiId}>
						<RecommendationsListItem item={item} ns={ns} />
					</React.Fragment>
				);
			})}
		</ul>
	);
};

export default RecommendationsList;
