import React, { useState } from 'react';
import { validate as validateEmail } from 'email-validator';
import classNames from 'classnames';
import { tryAtob } from '../../utils';
import Button from '../Button';
import Modal from '../Modal';

import { KLAVIYO_COMPANY_ID } from '../../utils/config';

const ns = 'site-modal--notify-me';

const NotifyMeModal = ({ onClose, variantId, comingSoonMetafieldValue, handle }) => {
	const rootClassNames = classNames({
		[`${ns}`]: true,
	});

	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = (e) => {
		setEmail(e?.currentTarget?.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const variantID = tryAtob(variantId)?.split('/').pop();
		const isValidEmail = validateEmail(email);

		if (!KLAVIYO_COMPANY_ID || !variantId) {
			setError(
				`An unexpected error occurred. Please contact us for assistance.`
			);

			return;
		}

		if (!isValidEmail) {
			setError('Please enter a valid email address.');

			return;
		}

		setIsLoading(true);

		const body = comingSoonMetafieldValue ? {
			email,
			g: comingSoonMetafieldValue,
			$fields: `$prod-${handle}`,
			[`$prod-${handle}`]: 'notify',
		} : {
			a: KLAVIYO_COMPANY_ID,
			email,
			variant: variantID,
			platform: 'shopify',
		};

		try {
			await fetch(
				comingSoonMetafieldValue ?
				'https://manage.kmail-lists.com/ajax/subscriptions/subscribe' :
				'https://a.klaviyo.com/onsite/components/back-in-stock/subscribe',
				{
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: new URLSearchParams(body).toString(),
				}
			);

			setError('');
			setIsSuccess(true);
			setIsLoading(false);
		} catch (err) {
			console.error(err);

			setError(
				'An unexpected error occurred. Please try again in a few minutes.'
			);
			setIsSuccess(false);
			setIsLoading(false);
		}
	};

	const descriptionText = comingSoonMetafieldValue
		? 'Want to be the first to shop this new drop? Enter your email address below to receive a notification when this product is available.'
		: 'Enter your email address below to receive a notification when this item is back in stock.';

	return (
		<Modal
			className={rootClassNames}
			hasOverlay
			onRequestClose={onClose}
			shouldFocusAfterRender
			bodyOpenClassName={`open-notify-me`}
			isOpen
		>
			<form className={`${ns}__form`} onSubmit={handleSubmit}>
				<div className={`${ns}__content`}>
					{!isSuccess && (
						<>
							<h4 className={`${ns}__title`}>{`GET ON THE LIST`}</h4>
							<p className={`${ns}__description`}>{descriptionText}</p>
							<input
								className={`${ns}__input`}
								placeholder="Enter your email"
								onChange={handleChange}
								value={email}
								name="email"
							/>
							<Button
								className={`${ns}__submit-button`}
								disabled={isLoading}
								type="submit"
							>
								Notify Me
							</Button>
							<button
								className={`${ns}__cancel-button`}
								onClick={onClose}
								type="button"
							>
								Nevermind
							</button>
						</>
					)}

					{error && <p className={`${ns}__error-message`}>{error}</p>}

					{isSuccess && (
						<>
							<h4 className={`${ns}__title`}>{`YOU'RE IN!`}</h4>
							<p
								className={`${ns}__success-message`}
							>{`You'll be notified via email when this item is ${comingSoonMetafieldValue ? 'available' : 'back'}.`}</p>
							<Button
								className={`${ns}__submit-button`}
								disabled={isLoading}
								onClick={onClose}
								type="button"
							>
								Close
							</Button>
						</>
					)}
				</div>
			</form>
		</Modal>
	);
};

export default NotifyMeModal;
