import React from 'react';
import classNames from 'classnames';

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

import * as config from '../../utils/config';
import RenderStaticHTML from '../RenderStaticHTML/RenderStaticHTML';
import Link from '../Link';

const ns = 'rich-text';

const options = {
	renderMark: {
		[MARKS.BOLD]: text => { return <b className={'font-bold'}>{text}</b>; },
		[MARKS.ITALIC]: text => { return <i className={'italic'}>{text}</i>; },
		[MARKS.UNDERLINE]: text => { return <u className={'underline'}>{text}</u>; },
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (_node, children) => {
			let content;

			switch(true){
				//	Guard cases
				case !children:
				case Array.isArray(children) && children.length === 0:	//	eslint-disable-line
					return null;

				//	We're given an array of nodes
				case Array.isArray(children):
					content = children.filter(_=>_);	//	eslint-disable-line
					break;

				//	We're given a single node
				default:
					content = children;
			}

			if (content[0]?.type === 'code') {

				//	The following intentionally seeks `<script>` elements in `<code>` nodes and places them in the page head to be initialized.
				//	Otherwise, the browser will not parse & execute the `<script>` from within Rich Text.
				const contentString = String(content[0].props.children);
				const matches = contentString.matchAll(/<script.+src="([^"]+)"[^>]*>/gi);
				const scriptSources = Array.from(matches).map(match=>{
					const scriptSrc = match?.[1];
					return scriptSrc;
				}).filter(_=>_);	//	eslint-disable-line arrow-body-style

				//	Uniqueness:
				//	A long story short, we need to prevent the same script source from loading twice... so we make our script-source list unique.
				//
				//	FourSixty's Instagram Widget scripts invoke `FoursixtyEmbed.init();` onload.
				//	Yet their platform doesn't keep track of a widget being already initialized.
				//	This results in duplicates of a widget being made if a script is loaded more than once.
				//	Their platform also doesn't support multiple widgets of the same type on a single page.
				//	So if there are multiple widget placeholders placed on a page each with a script,
				//	this results in duplicates of the widget being initialized but only the last widget found will be duplicated and the others won't show at all.
				//	It's a very poor platform.
				const uniqueScriptSources = Array.from(new Set(scriptSources));

				//	We've added an Environment Variable to enable/disable this script handling behavior via config.

				return (
					<>
						{config.ADD_RICH_TEXT_SCRIPTS_TO_HEAD && !!uniqueScriptSources.length &&
							<Helmet>
								{
									//	eslint-disable-next-line arrow-body-style
									uniqueScriptSources.map(src => <script key={src} src={src} async defer />)
								}
							</Helmet>
						}
						<RenderStaticHTML
							html={`${ contentString }`}
						/>
					</>
				);
			}

			return <p>{content}</p>;
		},
		[INLINES.HYPERLINK]: (node, children) => {
			const {data: {uri}} = node;
			// TODO: Sanitize URLS
			return <Link href={uri}>{children}</Link>;
		},
		'embedded-asset-block': (node) => {
			const {
				data: {
					target: {
						gatsbyImageData,
					},
				},
			} = node;

			if (!gatsbyImageData) {
				// asset is not an image
				return null
			}
			return <GatsbyImage image={gatsbyImageData} />
		},
	},
	renderText: text => {
		//	Although we are allowed to return a String(), simply replacing newlines with a <br/> JSX React element
		//	doesn't work because .replace() ultimately boils down our <br/> JSX React element into a string.
		//	And objects are manifested into a String as [Object, Object].
		//		return text.replace(/\n/g,<br />);
		//	So instead we leverage the fact we're allowed to return a mixed Array.
		//	In this case our Array elements are not converted to a String, they remain whatever type they are in the array.
		//	So some are String and other are JSX React elements.
		//	In the following, we stitch-in <br/> JSX React elements in place of where the newlines were.

		const nodes = [];
		text.split(/\n/g).forEach((node, index, array)=>{
			nodes.push(node);
			if (array.length-1 <= index) return;	//	Don't add <br/> after the last natural element.
			// eslint-disable-next-line react/no-array-index-key
			nodes.push(<br key={index} />);
		});
		return nodes;
	},
};

export default function RichText({fieldData, fieldName, defaultClass}) {

	if (!fieldData?.raw) return fieldData;

	const reactNodes = renderRichText(fieldData, options);

	const rootClassnames = classNames(`${ ns }`, {
		[`${ ns }__${ fieldName }`]: fieldName,
		[defaultClass]: defaultClass,
	});
	return (
		<div className={rootClassnames}>
			{reactNodes}
		</div>
	);
}
