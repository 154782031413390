import * as React from 'react';

function Plus(props) {
	return (
		<svg
			width={'15'}
			height={'16'}
			viewBox={'0 0 15 16'}
			fill={'none'}
			xmlns={'http://www.w3.org/2000/svg'}
			{...props}
		>
			<path
				d={'M7.623.5v15M0 8.123h15'}
				stroke={'currentColor'}
			/>
		</svg>
	);
}

export default Plus;
