import React, { useContext } from 'react';
import cn from 'classnames';
import RecommendationsList from './RecommendationsList';
import Title from '../Title';
import { useProductRecommendations } from '../../utils/hooks';
import { StoreContext } from '../../context/store-context';
import { REBUY_CUSTOM_DATA_SOURCE_ID } from '../../utils/config';

const ns = `recommendations-list`;

const Recommendations = (props) => {
	const {
		productIds,
		variantIds,
		limit,
		title,
		description,
		isInCart,
		...rest
	} = props;
	const { cart } = useContext(StoreContext);

	const canUseCustomDataSource = !!REBUY_CUSTOM_DATA_SOURCE_ID;
	const cartSubtotal = cart?.cost?.subtotalAmount?.amount;
	const cartLineCount = cart?.lineItems?.length;
	const cartItemCount = cart?.lineItems?.reduce((previous, current) => {
		return previous + (current?.quantity || 0);
	}, 0);

	const params = {
		limit: limit ?? 5,
		shopifyProductIds: productIds,
		shopifyVariantIds: variantIds,
		...(!canUseCustomDataSource && { variant_price_greater_than: `0.00` }),
		...(canUseCustomDataSource && cartSubtotal && { cartSubtotal }),
		...(canUseCustomDataSource && cartLineCount && { cartLineCount }),
		...(canUseCustomDataSource && cartItemCount && { cartItemCount }),
	}

	const { isLoading, isEmpty, data } = useProductRecommendations(params, canUseCustomDataSource);

	const rootClassnames = cn({
		[`${ns}`]: true,
		[`${ns}--page-component`]: !isInCart,
	});

	if (isLoading || isEmpty) {
		return null;
	}

	return (
		<div {...rest} className={rootClassnames}>
			<div className={cn(`${ns}__title`)}>
				<Title
					title={
						title && isInCart
							? `Recommended for you`
							: `You May Also Like`
					}
					description={description}
					cta={null}
				/>
			</div>
			<RecommendationsList ns={ns} items={data} />
		</div>
	);
};

export default Recommendations;
