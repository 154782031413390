/* eslint-disable no-plusplus */
/* eslint-disable prefer-template */

import React, { useState } from 'react';
import Button from '../Button';
import useAddToCartQuery from './useAddToCartQuery';
import { StoreContext } from '../../context/store-context';
import { tryAtob, tryBtoa } from '../../utils';
import {
	REQUIRES_PRODUCT_ID_KEY,
	REQUIRES_VARIANT_ID_KEY,
	KLAVIYO_COMPANY_ID,
	COMING_SOON_LIST_ID,
	COMING_SOON_LABEL,
	SOLD_OUT
} from '../../utils/config';
import NotifyMeModal from '../NotifyMeModal/NotifyMeModal';

const AddToCart = ({
	variantId,
	gqlProductId,
	gqlVariantId,
	styleVariant,
	quantity,
	customAttributes = [],
	sellingPlanId,
	available,
	handle,
	metafields,
	disableNotify,
	loadingText,
	addText = 'Add To Bag',
	oosText = 'Out of Stock',
	onAddToCart = () => { },
	...props
}) => {
	const { contentfulGlobalSettings } = useAddToCartQuery();
	const [loading, setLoading] = useState(false);
	const [displayNotifyMeModal, setDisplayNotifyMeModal] = useState(false);
	const isSoldOut = (metafields || []).find(metafield => { return metafield.key === SOLD_OUT })?.value;
	const comingSoonMetafieldValue = (metafields || []).find(metafield => { return metafield.key === COMING_SOON_LIST_ID })?.value;
	const enableNotify = (!loading && !available && KLAVIYO_COMPANY_ID && !isSoldOut) && !disableNotify;

	const items = [
		{
			variantId,
			quantity,
			sellingPlanId,
			attributes: customAttributes,
		},
	];

	const { addVariantsToCart, cartFlyoutOpen } = React.useContext(StoreContext);

	// We need to add gift items from the client as Shopify Scripts can only handle items already in the cart
	// Shopify Scripts takes care of the logic for quantity control
	function withGiftItems(initialItems = []) {
		const giftItems = [];
		const bxgyPromos = contentfulGlobalSettings.buyXGetYDiscounts || [];

		for (let i = 0; i < bxgyPromos.length; i++) {
			const promo = bxgyPromos[i] || {};
			const { freeVariantToAdd, productToBuy, variantToBuy } = promo;

			if (freeVariantToAdd) {
				const gqlPidX = tryAtob(productToBuy);
				const gqlVidX = tryAtob(variantToBuy);
				const gqlVidY = tryAtob(freeVariantToAdd);
				const variantIdY = tryBtoa(gqlVidY);

				if (productToBuy && gqlPidX === gqlProductId) {
					giftItems.push({
						quantity,
						variantId: variantIdY,
						attributes: [
							{
								key: REQUIRES_PRODUCT_ID_KEY,
								value: gqlProductId.split('/').pop(),
							},
						],
					});
				}

				if (variantToBuy && gqlVidX === gqlVariantId) {
					giftItems.push({
						quantity,
						variantId: variantIdY,
						attributes: [
							{
								key: REQUIRES_VARIANT_ID_KEY,
								value: gqlVariantId.split('/').pop(),
							},
						],
					});
				}
			}
		}

		return initialItems.concat(giftItems);
	}

	const addToCart = (e) => {
		e.preventDefault();

		setLoading(true);

		const variants = withGiftItems(items);

		return addVariantsToCart(variants).then(() => {
			onAddToCart();
			cartFlyoutOpen();
			setLoading(false);
		});
	};

	const renderButtonText = () => {
		let string = 'Loading...';

		if (loading && loadingText) {
			return loadingText;
		}

		if (available === undefined) {
			string = 'Loading...';
		} else if (available) {
			string = addText || 'Add To Bag';
		} else if (enableNotify) {
			string = 'Notify Me When In Stock';
		} else {
			string = oosText || 'Out of Stock';
		}

		if (comingSoonMetafieldValue) {
			string = COMING_SOON_LABEL;
		}

		return string;
	};

	const handleNotifyMeModal = () => {
		return setDisplayNotifyMeModal(true);
	};

	const handleComingSoonModal = () => {
		return setDisplayNotifyMeModal(true);
	};

	const handleNotifyMeModalClose = () => {
		return setDisplayNotifyMeModal(false);
	};

	const handleClick = (e) => {
		if (comingSoonMetafieldValue) {
			handleComingSoonModal(e);
		} else if (enableNotify) {
			handleNotifyMeModal(e);
		} else {
			addToCart(e);
		}
	};

	return (
		<>
			<Button
				type={'submit'}
				variant={styleVariant}
				onClick={handleClick}
				disabled={loading || (!available && !enableNotify)}
				{...props}
			>
				{renderButtonText()}
			</Button>
			{(enableNotify || comingSoonMetafieldValue) && displayNotifyMeModal && (
				<NotifyMeModal
					handle={handle}
					variantId={variantId}
					onClose={handleNotifyMeModalClose}
					comingSoonMetafieldValue={comingSoonMetafieldValue}
				/>
			)}
		</>
	);
};

export default AddToCart;
