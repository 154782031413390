import React from 'react';
import classNames from 'classnames';

const ns = `afterpay`;

const Afterpay = ({
	currencyCode,
	price,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const locale = process.env.GATSBY_LOCALE_CODE || 'en_US';

	return (
		<div className={rootClassnames}>
			<afterpay-placement
				data-locale={locale}
				data-currency={currencyCode}
				data-amount={price}
				data-modal-theme={'white'}
				data-size={'xs'}
				data-logo-type={'lockup'}
				data-intro-text={'Pay in'}
			/>
		</div>
	);
};

export default Afterpay;
