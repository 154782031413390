import { useStaticQuery, graphql } from 'gatsby';

const useCartQuery = () => {
	const data = useStaticQuery(graphql`
		query {
			contentfulGlobalSettings {
				cartFreeShippingThreshold
				cartEnableFreeShippingMessage
				cartEnableFreeSamplesStrategy
				cartFreeSamplesSubtotalThreshold
				cartFreeSamplesQualifyingProductThreshold
				cartFreeSamplesCollection
				cartEnableFreeGiftStrategy
				cartFreeGiftSubtotalThreshold
				cartFreeGiftQualifyingProductThreshold
				cartFreeGiftProduct
				totalAllowedFreeSamples
				recommendedItemsEnabledCart
			}
		}
	`);

	return data;
};

export default useCartQuery;
