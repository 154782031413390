import * as React from 'react';

function ShoppingBag(props) {
	return (
		<svg
			width={`22`}
			height={`22`}
			viewBox={`0 0 22 22`}
			fill={`none`}
			xmlns={`http://www.w3.org/2000/svg`}
			{...props}
		>
			<path
				d={`M7 6V4.326C7 2.49 8.79 1 11 1s4 1.49 4 3.326V6`}
				stroke={`currentColor`}
				strokeLinecap={`round`}
				strokeLinejoin={`round`}
			/>
			<path
				d={`m18.214 6.817.728 13.128A1 1 0 0 1 17.943 21H4.057a1 1 0 0 1-.999-1.055l.727-13.128a.892.892 0 0 1 .9-.817h12.629c.475 0 .868.357.9.817Z`}
				stroke={`currentColor`}
				strokeLinecap={`round`}
				strokeLinejoin={`round`}
			/>
			<path
				d={`M7.976 17h4.74l.46-.85h-3.67l1.9-2.11c.9-.99 1.29-1.59 1.29-2.45 0-1.2-.88-1.98-2.18-1.98-1.09 0-1.88.51-2.5 1.27l.15 1.06h.02c.62-.83 1.36-1.46 2.28-1.46.8 0 1.29.55 1.29 1.2 0 .64-.33 1.11-1.12 1.99l-2.73 3.08.07.25Z`}
				fill={``}
			/>
		</svg>
	);
}

export default ShoppingBag;
