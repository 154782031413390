import React from 'react';
import {
	connectStateResults
} from 'react-instantsearch-dom';

/**
 * Wrapper component that provides Algolia's search state objects to its children.
 *
 * For connectStateResults usage see: https://www.algolia.com/doc/api-reference/widgets/state-results/react/#connector
 *
 * @param {object} searchState State object received from Algolia connectStateResults() HOC.
 * @param {object} searchResults Results object received from Algolia connectStateResults() HOC.
 * @returns Result of the render prop that is passed in along with the searchState and searchResults
 * objects.
 */
const AlgoliaStateResults = ({
	searching, isSearchStalled, searchState, searchResults, render,
}) => {
	// console.log({searchState});
	// console.log({searchResults});

	return (
		<>
			{
				render({
					searching,
					isSearchStalled,
					searchState,
					searchResults,
				})
			}
		</>
	);
};

export default connectStateResults(AlgoliaStateResults);
