/* eslint-disable max-len */
import React, { useContext } from 'react';
import classNames from 'classnames';
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch-dom';
import AlgoliaStateResults from '../../utils/algolia-state-results';
import { SearchContext } from '../../context/search-context';

import Modal from '../Modal';
import Hits from './Hits';

const ns = `search`;
const nsModal = `${ ns }-modal`;

const Search = ({ isPreHeaderBarHidden, hasPreHeader }) => {
	const {
		searchClient,
		handleSearchResults,
		searchInputValue,
		searchState,
		handleSearchClose,
		handleSearchChange,
		handleSearchSubmit,
	} = useContext(SearchContext);
	const rootClassnames = classNames({ [`${ ns }`]: true });
	const modalClassNames = classNames({
		[`${ nsModal }`]: true,
		[`${ nsModal }--preheader-bar-hidden`]: isPreHeaderBarHidden,
		[`${ nsModal }--has-preheader`]: hasPreHeader,
	});

	return (
		<div className={rootClassnames}>
			<InstantSearch
				indexName={`Products`}
				searchClient={searchClient}
				searchState={{ query: searchState }}
			>
				<Configure distinct />
				<SearchBox
					className={`${ ns }__search-form`}
					translations={{ placeholder: `SEARCH` }}
					onChange={handleSearchChange}
					onSubmit={e => { handleSearchSubmit(e, searchInputValue); }}
					searchAsYouType={false}
					showReset={false}
					showSubmit={false}
					autoFocus
				/>
				<AlgoliaStateResults
					render={({ searchResults }) => {
						// Renders and keeps stores search results
						handleSearchResults(searchResults);

						return (
							<Modal
								className={modalClassNames}
								hasOverlay={false}
								onRequestClose={() => { handleSearchClose(); }}
								shouldFocusAfterRender={false}
								bodyOpenClassName={`open-search`}
								isOpen
								variant={'search'}
							>
								<Hits />
							</Modal>
						);
					}}
				/>
			</InstantSearch>
		</div>
	);
};

export default Search;
