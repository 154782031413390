import React, { useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import classNames from 'classnames';
import { useIsClient } from '../../utils/hooks';
import { dataLayerPush } from '../../utils';
import { identifyKlaviyoProfile } from '../../utils/klaviyo';

const ns = 'newsletter';
const KLAVIYO_LIST_ENDPOINT = 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe';

const validationSchema = yup.object({
	email: yup.string().email('Invalid email address').required('Email is required'),
	g: yup.string().required(),
}).required();

// Reference for Klaviyo Forms:
// https://help.klaviyo.com/hc/en-us/articles/115005080167-How-to-Redirect-Existing-Signup-Forms-to-Klaviyo#redirecting-a-custom-form-to-klaviyo2
// https://community.klaviyo.com/developer-group-64/implement-embed-klaviyo-forms-in-spa-apps-like-gatsbyjs-reactjs-808

export default function Newsletter({newsletterListId, title}) {
	const isClient = useIsClient();
	const [alreadySubscribed, setAlreadySubscribed] = useState(false);

	const {
		register, handleSubmit,
		formState: {
			errors, isSubmitting, isSubmitSuccessful,
		},
	} = useForm({
		defaultValues: {
			g: newsletterListId,
		},
		resolver: yupResolver(validationSchema),
	});

	const successMessage = alreadySubscribed ? `Guess what? You're already subscribed!` : `Thank you! Please check your email to confirm.`;

	const onSubmit = async data => {
		identifyKlaviyoProfile({email: data?.email});
		const payload = new URLSearchParams(data);
		try {
			const newsletterResponse = await fetch(KLAVIYO_LIST_ENDPOINT, {
				body: payload,
				method: 'POST',
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
					'cache-control': 'no-cache',
				},
			}).then(response => { return response.json(); });
			// eslint-disable-next-line camelcase
			const {data: {is_subscribed, email}} = newsletterResponse;
			setAlreadySubscribed(is_subscribed);
			dataLayerPush().signUp({ type: 'email_subscription', email });
		} catch (err) {
			// eslint-disable-next-line
			console.error('Failed to subscribe newsletter', err);
		}
	};

	useEffect(() => {
		const dataLayerHandler = (e) => { 
			if (e.detail?.type === 'submit') {
				dataLayerPush().signUp({ type: 'email_subscription', email: e.detail?.metaData?.$email });
			}
		}
		window.addEventListener('klaviyoForms', dataLayerHandler);
		return () => {
			window.removeEventListener('klaviyoForms', dataLayerHandler);
		}
	}, []);

	return (
		<div className={`${ ns }__container`}>
			<p className={'text-small font-medium'}>{title}</p>
			{isSubmitSuccessful ? (
				<div className={'success text-small'}>
					{successMessage}
				</div>
			)
			// Rendering form on server is having issues with hydration
			// If we click on the button before hydration is complete
			// it will submit the form and refresh rather than going to
			// form handler. Therefore, only rendering form on client
				: isClient && (
					<form onSubmit={handleSubmit(onSubmit)}>
						<input {...register('g')} id={'g'} type={'hidden'} />
						<input
							{...register('email')}
							type={'email'}
							placeholder={'Email Address'}
							className={classNames({'has-error': errors.email})}
							disabled={isSubmitting}
						/>
						<button disabled={isSubmitting} type={'submit'}>SIGN UP</button>
					</form>
				)}
			{errors.email?.message && (
				<div className={'error text-small'}>
					{errors.email.message}
				</div>
			)}
		</div>
	);
}
