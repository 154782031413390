/* eslint-disable no-underscore-dangle */
import React, {
	useMemo,
	useCallback,
	useState,
	useEffect
} from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getShopifyImage } from 'gatsby-source-shopify';

import NumericInput from '../../NumericInput';
import Price from '../../Price';

import { StoreContext } from '../../../context/store-context';
import renderIcon from '../../../utils/renderIcon';
import { createCustomAttributesMap, dataLayerPush } from '../../../utils';
import {
	REQUIRES_PRODUCT_ID_KEY,
	REQUIRES_VARIANT_ID_KEY,
} from '../../../utils/config';

const ns = `cart-line-item`;

const LineItem = ({
	item,
}) => {

	const {
		removeLineItem,
		cart,
		cartLineItemsUpdatedAt,
		updateLineItem,
		loading,
	} = React.useContext(StoreContext);

	const [quantity, setQuantity] = useState(item.quantity);

	useEffect(() => {
		//	eslint-disable-next-line max-len
		//	If lineItems have updated but item.quantity & quantity are already equivalent, do not continue setting state.
		if (quantity !== item.quantity) setQuantity(item.quantity);
	}, [cartLineItemsUpdatedAt]);	//	eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * Custom attributes map
	 */

	const customAttributesMap = createCustomAttributesMap(item.attributes) || {};

	/**
	 * Item image
	 */

	const variantImage = {
		...item?.variant?.image,
		originalSrc: item?.variant?.image?.url,
	};

	const image = useMemo(() => {
		if (variantImage.url) {
			return getShopifyImage({
				image: variantImage,
				layout: 'constrained',
				width: 130,
				height: 164,
			});
		}

		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variantImage?.url]);

	/**
	 * Item remove
	 */

	const handleRemove = e => {
		e.preventDefault();

		let lineItemsToRemove = [item.id];
		const freeCompanionVaraintId = customAttributesMap?._free_companion_variant_id;

		// If a lineItem has a freeCompanionVariantId
		// we need to remove that item from the cart

		if (freeCompanionVaraintId) {
			const freeCompanionLineItem = cart.lineItems
				.filter(lineItem => {
					const variantId = lineItem.variant?.id;
					return freeCompanionVaraintId === variantId;
				})
				.map(lineItem => {
					return lineItem.id;
				});

			lineItemsToRemove = [...lineItemsToRemove, ...freeCompanionLineItem];
		}

		removeLineItem(cart.id, lineItemsToRemove).then(() => {
			dataLayerPush().removeFromCart({
				product: item,
				variant: item.variant,
				quantity: item.quantity,
			});
		});
	};

	const uli = debounce(
		value => {
			let lineItemsToUpdate = [{
				id: item.id,
				quantity: value,
				attributes: item.attributes,
			}];
			const freeCompanionVaraintId = customAttributesMap?._free_companion_variant_id;

			// If a lineItem has a freeCompanionVariantId
			// we need to update that item's quantity

			if (freeCompanionVaraintId) {
				const freeCompanionLineItem = cart.lineItems
					.filter(lineItem => {
						const variantId = lineItem.variant?.id;
						return freeCompanionVaraintId === variantId;
					})
					.map(lineItem => {
						return {
							id: lineItem.id,
							quantity: value,
						};
					});

				lineItemsToUpdate = [...lineItemsToUpdate, ...freeCompanionLineItem];
			}

			updateLineItem(cart.id, lineItemsToUpdate);
		},
		300
	);

	/**
	 * Item quantity
	 */

	const debouncedUli = useCallback(value => {
		uli(value);
	// eslint-disable-next-line
	}, []);

	const handleQuantityChange = value => {
		if (value !== '' && Number(value) < 1) {
			return;
		}

		setQuantity(value);

		if (Number(value) >= 1) {
			debouncedUli(value);
		}
	};

	function doIncrement() {
		handleQuantityChange(Number(quantity || 0) + 1);
	}

	function doDecrement() {
		handleQuantityChange(Number(quantity || 0) - 1);
	}

	/**
	 * Item selected
	 */

	const renderSelectedOptions = title => {
		if (!title || title === 'Default Title') return null;

		return (
			<div className={`${ ns }__options`}>
				{title.split('/').join('-')}
			</div>
		);
	};

	/**
	 * Bundle Items
	 */
	const renderBundleItems = customAttributes => {
		const bundleItems = customAttributes.filter(customAttribute => {
			return customAttribute.key.includes('Product Name');
		});

		return (
			<div className={`${ ns }__bundle-items`}>
				{bundleItems && bundleItems.map(bundleItem => {
					return <div key={bundleItem.key} className={`${ ns }__bundle-item`}>{bundleItem.value}</div>;
				})}
			</div>
		);
	};

	/**
	 * BOGO Promo
	 */
	const renderBOGO = (tags = [], productType = null) => {
		if(tags && tags.length === 0) {
			return null;
		}

		const bogoTag = tags && tags.find(tag =>{ return tag.includes('promo::Buy') });
		let promoText = ``;
		let bogoNode = null;

		if (item?.cost?.totalAmount?.amount === '0.0') {
			return ''
		}

		if(bogoTag) {
			switch(bogoTag) {
				case 'promo::Buy1Get1':
					promoText = `🎁 Take 2 and 2nd is free!`;
					break;
				case 'promo::Buy2Get1':
					promoText = `🎁 Take 3 and 3rd is free!`;
					break;
				case 'promo::Buy3Get1':
					promoText = `🎁 Take 4 and 4th is free!`;
					break;
				case 'promo::Buy4Get1':
					promoText = `🎁 Take 5 and 5th is free!`;
					break;
				case 'promo::BuyXGetYByType':
					promoText = productType === 'Health & Beauty' ? `🎁 Buy Brow Serum get a Last Serum free!` : ``;
					break;
				default:
					promoText = ``;
					break;
			}

			bogoNode = promoText.length > 0 ? (
				<div className={`font-bold ${ ns }__bogo-promo`}>
					{promoText}
				</div>
			) : null;
		}

		return bogoNode;
	};

	/**
	 * Price
	 */

	const renderPrice = (totalAmount, price, compareAtPrice, currencyCode, sellingPlanJson, sellingPlanAllocationAmount, qty) => {
		const precision = 100;
		const wholeAmount = Number(totalAmount) * precision;
		const lineItemPrice = Math.floor(wholeAmount / Number(qty)) / precision;

		//	-----------------------------
		//	Render Price with SellingPlan
		//	-----------------------------
		let sellingPlan;

		if (sellingPlanJson) {
			try {
				sellingPlan = JSON.parse(sellingPlanJson);
			} catch (error) {
				// eslint-disable-next-line
				console.error(error);
			}
		}

		if (sellingPlan) {
			const discount = parseInt(sellingPlan.discount_amount, 10);
			// const autoRenewPrice = price - (price * (discount / 100));

			const intervalUnit = sellingPlan.subscription_preferences.interval_unit;
			const intervalFreq = sellingPlan.subscription_preferences.charge_interval_frequency;
			const intervalUnitPluralized = intervalFreq !== 1 ? `${ intervalUnit }s` : intervalUnit;

			return (
				<>
					<div className={`${ ns }__subsription-price`}>
						<Price
							price={sellingPlanAllocationAmount}
							compareAtPrice={price} // price becomes compareAtPrice in this case
							currencyCode={currencyCode}
						/>
						<div className={`${ ns }__subsription-renew-discount`}>
							{`${ discount }% off Auto Renew`}
						</div>
					</div>
					<div className={`${ ns }__subsription-renew-cadence`}>
						{`Deliver every ${ intervalFreq } ${ intervalUnitPluralized }`}
					</div>
				</>
			);
		}

		//	--------------------------------
		//	Render Price without SellingPlan
		//	--------------------------------
		const itemIsFree = ((_item) => {
			//	Early realization
			if( lineItemPrice === 0 )	return true;

			//	Sometimes Shopify doesn't return the proper cart data.
			//	Yet the hosted checkout will behave properly.
			//	And we have to determine if the item really will be free in checkout but is just displaying at not-free from the Storefront API.
			//	eslint-disable-next-line arrow-body-style
			const {key = null, value = null} = _item.attributes.find(_=>_.key==='_requires_product_id') || {};		// 	Value from LineItem.attribute
			const isSelfReferencing = value === _item.merchandise?.product?.id?.split('/').pop();	//	Value from LineItem Product.id
			const isBOGO = _item.merchandise?.product?.tags?.some((tag) => {
				return /promo::Buy\dGet\d/.test(tag) || /promo::BuyXGetY/.test(tag);
			});
			return isSelfReferencing && isBOGO;
		})(item);

		const priceMarkup = (
			<Price
				price={price}
				compareAtPrice={compareAtPrice}
				currencyCode={currencyCode}
			/>
		);

		const freePriceMarkup = <p className={'font-bold'}>🎁 Free!</p>;

		// Discounted price from Shopify Scripts
		const discountedPriceMarkup = (
			<Price
				price={lineItemPrice}
				compareAtPrice={compareAtPrice}
				currencyCode={currencyCode}
			/>
		);

		let markup;
		switch( true ){
			case itemIsFree:
				markup = freePriceMarkup;
				break;
			case lineItemPrice < price:
				markup = discountedPriceMarkup
				break;
			default:
				markup = priceMarkup;
		}

		return markup;
	};

	const isSoldOut = item.quantity === 0;

	const doShowQuantitySelector = (
		!isSoldOut
		&& !customAttributesMap?._free_sample
		&& !customAttributesMap?._free_gift
		&& !customAttributesMap[REQUIRES_PRODUCT_ID_KEY]
		&& !customAttributesMap[REQUIRES_VARIANT_ID_KEY]
	);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<li className={rootClassnames}>
			{image && (
				<div className={`${ ns }__left`}>
					<div className={`${ ns }__image`}>
						<GatsbyImage
							key={variantImage.src}
							image={image}
							alt={variantImage.altText ?? item.variant?.title ?? item.title}
						/>
					</div>
				</div>
			)}
			<div className={`${ ns }__right`}>
				<div className={`${ ns }__right-container`}>
					<div className={`${ ns }__right-top`}>
						<h6 className={`${ ns }__title`}>{item.title}</h6>
						{renderSelectedOptions(item.variant?.product?.title)}
						{renderBundleItems(item.attributes)}
						{renderBOGO(item.variant?.product?.tags, item.variant?.product?.productType)}
						<div className={`${ ns }__price`}>
							{ isSoldOut ? (
								<span>This item is temporarily out-of-stock</span>
							) : renderPrice(
								Number(item?.cost?.totalAmount?.amount),
								Number(item?.variant?.price?.amount),
								Number(item?.variant?.compareAtPrice?.amount),
								item?.cost?.totalAmount?.currencyCode,
								customAttributesMap?._selling_plan_json,
								item?.sellingPlanAllocation?.checkoutChargeAmount?.amount,
								item?.quantity
							)}
						</div>
					</div>
					<div className={`${ ns }__right-bottom`}>
						{ doShowQuantitySelector && (
							<div className={`${ ns }__quantity`}>
								<NumericInput
									disabled={loading}
									value={quantity}
									aria-label={`Quantity`}
									onIncrement={() => { doIncrement(); }}
									onDecrement={() => { doDecrement(); }}
									onChange={e => { handleQuantityChange(e.currentTarget.value); }}
								/>
							</div>
						)}
						{(!doShowQuantitySelector && item.quantity > 1) && (
							<div className={`${ ns }__quantity`}>
								<div><span>X {quantity}</span></div>
							</div>
						)}
					</div>
					<div className={`${ ns }__remove`}>
						<button onClick={handleRemove}>
							<span className={`visually-hidden`}>Remove Item</span>
							<span aria-hidden>{renderIcon('close')}</span>
						</button>
					</div>
				</div>
			</div>
		</li>
	);
};

export default LineItem;
