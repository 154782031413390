import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import classNames from 'classnames';

import Link from '../Link';

const ns = `rte`;

/*
* parses & renders html string into dom nodes
* (WYSIWYG field type from Wordpress)
*/

const RenderStaticHTML = ({ html, className }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[className]: className,
	});

	if (!html) return null;

	const options = {
		// eslint-disable-next-line react/no-unstable-nested-components
		replace: node => {
			const {
				name,
				children,
				attribs,
				type,
				data,
			} = node;

			// render Link component for a tags

			if (name === 'a') {
				/* eslint-disable */
				return (
					<Link {...attribs} variant={'underline'}>
						{domToReact(children, options)}
					</Link>
				);
				/* eslint-enable */
			}

			//	Prior to the HTML being passed into this component,
			//	There was likely a newline `\n` replaced with a <br/> JSX React Element.
			//	When this `<br/>` is strigified and passed into this componet, it shows as [object Object] .
			//	We don't want that showing in the output on the page so we catch it and replace it with
			//	an HTML element that takes no whitespace.
			const isStringifiedReactComponent = ( type === 'text' && /,\[object Object\],/gi.test(data) );
			if (isStringifiedReactComponent) {
				return <span/>;
			}

			return false;
		},
	};

	const elements = parse(html, options);

	return (
		<div className={rootClassnames}>
			{elements}
		</div>
	);
};

export default RenderStaticHTML;
