import * as React from 'react';

function SearchIcon(props) {
	return (
		<svg
			width={'22'}
			height={'26'}
			viewBox={'0 0 22 23'}
			fill={'none'}
			xmlns={'http://www.w3.org/2000/svg'}
			{...props}
		>
			<path
				fillRule={'evenodd'}
				clipRule={'evenodd'}
				d={'M11.001 3.0332C8.36848 3.0332 6.23438 5.16731 6.23438 7.79987C6.23438 10.4324 8.36848 12.5665 11.001 12.5665C13.6336 12.5665 15.7677 10.4324 15.7677 7.79987C15.7677 5.16731 13.6336 3.0332 11.001 3.0332ZM7.23438 7.79987C7.23438 5.7196 8.92077 4.0332 11.001 4.0332C13.0813 4.0332 14.7677 5.7196 14.7677 7.79987C14.7677 9.88014 13.0813 11.5665 11.001 11.5665C8.92077 11.5665 7.23438 9.88014 7.23438 7.79987ZM11.0018 13.7852C14.6864 13.7852 17.6885 16.5777 17.95 20.0994H4.05366C4.31518 16.5777 7.31724 13.7852 11.0018 13.7852ZM11.0018 12.7852C6.61172 12.7852 3.03516 16.2741 3.03516 20.5994V21.0994H3.53516H18.4685H18.9685V20.5994C18.9685 16.2741 15.3919 12.7852 11.0018 12.7852Z'}
				fill={'currentColor'}
			/>
		</svg>
	);
}

export default SearchIcon;
