/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { connectHits } from 'react-instantsearch-dom';
import useNavigationQuery from '../../Navigation/useNavigationQuery';
import { useGetMediaSize } from '../../../utils/hooks';
import { SearchContext } from '../../../context/search-context';
import { getProductsById } from '../../../utils';

import Link from '../../Link';
import Button from '../../Button';

const ns = `search-hits`;

const HitLink = ({ handle, title, featuredImage }) => {
	return (
		<Link
			to={`/products/${ handle }`}
			className={`${ ns }__hit`}
			key={`search-product_${ handle }`}
		>
			<div className={`${ ns }__image-wrapper`}>
				{featuredImage && (
					<img className={`${ ns }__image`} alt={featuredImage.altText || title} src={featuredImage.src} />
				)}
			</div>
			<div className={`${ ns }__title-wrapper`}>
				{title && (
					<div className={`${ ns }__title`}>{ title }</div>
				)}
			</div>
		</Link>
	);
};

const Hits = () => {
	const {
		contentfulGlobalSettings: {
			searchSuggestionsPromotedItems,
			searchSuggestionsTitle,
			searchSuggestionsList,
		},
	} = useNavigationQuery();
	const {
		results,
		defaultResults,
		setDefaultResults,
		searchInputValue,
		handleSearchSubmit,
		handleSearchSuggestionClick,
	} = useContext(SearchContext);
	const {
		isMobile, isLaptop, isTablet, isDesktop, isWidescreen,
	} = useGetMediaSize();

	const isEmpty = !searchInputValue || searchInputValue === ``;
	const isEnabled = searchInputValue && searchInputValue.length > 2;
	const hasResults = results.current?.length;
	const mobileConditionsMet = !isMobile || (isMobile && !isEmpty && hasResults);
	const shouldRenderSuggestions = !isMobile || ((isMobile && isEmpty) || (isMobile && hasResults));

	const getMaxHits = () => {
		if (isTablet) return 4;
		if (isLaptop) return 6;
		if (isDesktop || isWidescreen) return 9;

		return 3;
	};

	const normalizeStorefrontResponse = (products = []) => {
		if (!products?.length) return products;

		return products.map(product => {
			return {
				handle: product?.handle,
				title: product?.title,
				featuredImage: product?.images?.nodes[0],
			};
		});
	};

	const rootClassnames = classNames({ [`${ ns }`]: true });

	useEffect(() => {
		let isMounted = true;

		(async () => {
			try {
				if (!isMounted && !defaultResults.length && searchSuggestionsPromotedItems) {
					const resolvedProducts = await getProductsById(searchSuggestionsPromotedItems);
					setDefaultResults(normalizeStorefrontResponse(resolvedProducts));
				}
			} catch (error) {
				// eslint-disable-next-line
				console.error(error.message);
			}
		})();

		return () => {
			isMounted = false;
		};
	}, [defaultResults, searchSuggestionsPromotedItems, setDefaultResults])

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__container ${ ns }__container-products`}>
				<div className={`${ ns }__container-content`}>
					<div className={`${ ns }__hits`}>
						{mobileConditionsMet ? (
							<div className={`${ ns }__column-title`}>
								{((hasResults || isEmpty) && isEnabled) ? `POPULAR PRODUCTS` : `SEARCH SUGGESTIONS` }
							</div>
						) : null}
						{mobileConditionsMet ? (
							<>
								{isEnabled && results && results.current.slice(0, getMaxHits()).map(result => {
									return <HitLink key={result.handle} {...result} />;
								})}
								{(!hasResults || !isEnabled) && defaultResults && defaultResults.slice(0, getMaxHits()).map(defaultHit => {
									return <HitLink key={defaultHit.handle} {...defaultHit} />;
								})}
							</>
						) : null}
					</div>
				</div>
			</div>
			<div className={`${ ns }__container ${ ns }__container-suggested`}>
				{shouldRenderSuggestions ? (
					<div className={`${ ns }__container-content`}>
						{searchSuggestionsList && (
							<>
								<div className={`${ ns }__column-title`}>{ searchSuggestionsTitle }</div>
								<div className={`${ ns }__column-suggestions`}>
									{searchSuggestionsList.map(item => {
										return (
											<Link
												to={`/`}
												className={`${ ns }__suggestion`}
												key={`suggestion_${ item }`}
												onClick={e => { handleSearchSuggestionClick(e, item); }}
												value={item}
											>
												<div>{ item }</div>
											</Link>
										);
									})}
								</div>
							</>
						)}
					</div>
				) : null}
			</div>
			<div className={`${ ns }__container ${ ns }__container-button`}>
				{!isEmpty && (
					<Button
						className={(!isEnabled || !hasResults) && `disabled`}
						variant={`text-white`}
						to={`/search-results`}
						withArrow={!isEnabled || hasResults}
						disabled={!hasResults}
						onClick={e => { handleSearchSubmit(e, searchInputValue); }}
					>
						{(!isEnabled || hasResults) ? `SHOW ALL RESULTS` : `THERE ARE NO MATCHING RESULTS` }
						{!isMobile && (
							<span>
								&nbsp;FOR&nbsp;&quot;
								{searchInputValue}
								&quot;
							</span>
						)}
					</Button>
				)}
			</div>
		</div>
	);
};

export default connectHits(Hits);
