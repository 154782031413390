/* eslint-disable */

import React from 'react';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
// import { HiOutlineArrowRight } from 'react-icons/hi';

import SafeAnchor from '../SafeAnchor';

import { isExternalUrl } from '../../utils';
import renderIcon from '../../utils/renderIcon';

/*
- href prop wll create a regular a-tag but still with button classes
- disabled prop will add 'tabindex' of -1 and 'aria-disabled' of 'true' for accessibility
- variant - prop will add a modifier to classname
*/

const ns = `button`;

const Button = React.forwardRef(
	(
		{
			active,
			className,
			variant,
			type,
			as,
			to,
			href,
			withArrow,
			...props
		},
		ref
	) => {
		// define button classes
		const rootClassnames = classNames(
			className,
			`${ ns }`,
			variant && `${ ns }--${ variant }`,
			withArrow && `${ ns }--with-arrow`,
			active && 'active'
		);

		// if (withArrow) {
		// 	// eslint-disable-next-line
		// 	props.children = (
		// 		<div>
		// 			<span className={`arrow`}>
		// 				<HiOutlineArrowRight size={15} />
		// 			</span>
		// 			<span>{props.children}</span>
		// 		</div>
		// 	);
		// }

		// setting the title on all buttons
		// for the hover state animation

		// We need to make sure that content of the button is string 
		// if props.children is not a string
		// It will show [object Object] in the tooltip 

		const buttonContent = typeof props.children ==='string' ? props.children : "";

		if(withArrow){
			props.children = <>
				{props.children}
				<span className='arrow'>{renderIcon('arrow')}</span>
			</>
		}
		
		props.title = props.title ?? buttonContent;

		if (href || isExternalUrl(to)) {
			return (
				<SafeAnchor
					{...props}
					href={href || to}
					as={as}
					ref={ref}
					target={'_blank'}
					className={classNames(rootClassnames, props.disabled && 'disabled')}
				/>
			);
		}

		if (ref) {
			props.ref = ref; // eslint-disable-line
		}

		if (!as) {
			props.type = type; // eslint-disable-line
		}

		if (to) {
			return (
				<GatsbyLink
					{...props} // eslint-disable-line
					to={to || '/'}
					ref={ref}
					className={rootClassnames}
				/>
			);
		}

		const Component = as || 'button';
		return <Component {...props} className={rootClassnames} />;
		/* eslint-enable */
	}
);

export default Button;
