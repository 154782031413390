export function identifyKlaviyoProfile({email}) {

	if( !email ) return false;

	const { klaviyo } = window || {};

	//	Make sure klaviyo is initialized at this time
	if( !(klaviyo && typeof klaviyo.push === 'function') ){
		window.console.log('There is no Klaviyo framework initialized.');
		return false;
	}

	klaviyo.identify({
		'$email' : email,
	});

	window.console.log('Klaviyo identified user.');

	return true;
}

function getExchangeToken() {
	/* eslint-disable no-underscore-dangle */
	const { _learnq } = window || {};

	//	Make sure _learnq is initialized at this time
	if( !(_learnq && typeof _learnq.push === 'function') ){
		window.console.log('There is no Klaviyo _learnq framework initialized.');
		return '';
	}

	//	An anonymous token Klaviyo assignes to this user session that is associated to a Klaviyo Profile
	const exchangeToken = _learnq.push(['_getIdentifiers'])?.$exchange_id;

	return exchangeToken;
}

function addParamsToUrl(url){
	const exchangeToken = getExchangeToken();

	//	Breakpoint
	if (!exchangeToken) return url;

	//	Update URL parameters
	const [baseUrl,searchParams=''] = url.split('?');
	const USP = new URLSearchParams(searchParams);
	USP.set('_kx', exchangeToken);
	const updatedUrl = `${baseUrl}?${USP.toString()}`;

	return updatedUrl;
}

export function addKlaviyoProfileIdToUrl(url) {
	return addParamsToUrl(url);
}
