import React from 'react';
import classNames from 'classnames';

const ns = `cart-free-shipping`;

const FreeShippingProgress = ({
	cartSubtotalAmount,
	goalAmount,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});
	const progress = parseFloat(cartSubtotalAmount);
	const goal = parseFloat(goalAmount);

	const percentTowardFreeShipping = (() => {
		let precentComplete = Math.round(progress/goal*100);

		if (precentComplete > 100) {
			precentComplete = 100;
		}

		return precentComplete;
	})();

	const amountAwayFromFreeShipping = (() => {
		const amountNeeded = (goal - progress).toFixed(2);

		return amountNeeded <= 0 ? 0 : amountNeeded;
	})();

	const hasAchievedFreeShipping = percentTowardFreeShipping >= 100;
	let message;

	switch (true) {
		case hasAchievedFreeShipping:
			message = (
				<p className={`font-medium`}>Congrats! You’ve unlocked Free Shipping!</p>
			);
			break;
		default:
			message = (
				<>
					<p className={`${ ns }__label`}>You are <span className={`font-bold`}>${amountAwayFromFreeShipping}</span> away from Free Shipping!</p>
					<progress max={"100"} value={percentTowardFreeShipping} className={`${ ns }__progress`} />
				</>
			);
	}

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__container`}>
				{message}
			</div>
		</div>
	);
};

export default FreeShippingProgress;
