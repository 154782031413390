import * as React from 'react';

function PlusSm(props) {
	return (
		<svg
			width={'10'}
			height={'9'}
			viewBox={'0 0 10 9'}
			fill={'none'}
			xmlns={'http://www.w3.org/2000/svg'}
			{...props}
		>
			<path
				fillRule={'evenodd'}
				clipRule={'evenodd'}
				d={'M5.523.5a.5.5 0 0 0-1 0V4h-3.5a.5.5 0 1 0 0 1h3.5v3.5a.5.5 0 1 0 1 0V5h3.5a.5.5 0 0 0 0-1h-3.5V.5Z'}
				fill={'#000'}
			/>
		</svg>
	);
}

export default PlusSm;
