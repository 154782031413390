import React, {useRef, useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import useHeaderQuery from './useHeaderQuery';

import Link from '../../components/Link';
import Navigation from '../../components/Navigation';

import renderIcon from '../../utils/renderIcon';

import { useIsScrollPositionPassThreshold, useGetMediaSize } from '../../utils/hooks';

const ns = `site-header`;
const brand = process.env.GATSBY_PBG_BRAND;

const Header = ({ pathname }) => {
	const {
		contentfulHeader: {
			preHeaderBanner,
			preHeaderBannerColor,
			promoBanner,
		},
	} = useHeaderQuery();

	const hasScrolledPastThreshold = useIsScrollPositionPassThreshold(100);
	const { isMobile } = useGetMediaSize();

	const [isPromoBarOpen, setIsPromoBarOpen] = useState(true);
	const [isPreHeaderBarHidden, setIsPreHeaderBarHidden] = useState(false);
	const promoBarRef = useRef(null);
	const preheaderRef = useRef(null);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--preheader-bar-hidden`]: isPreHeaderBarHidden,
	});

	const handleAnnouncementClose = () => {
		if (promoBarRef.current) {
			promoBarRef.current.style.opacity = 0;
			promoBarRef.current.style.visibility = 'hidden';
			setIsPromoBarOpen(false);
		}
	};

	useEffect(() => {
		if (promoBarRef.current) {
			if (hasScrolledPastThreshold && isPromoBarOpen) {
				promoBarRef.current.style.opacity = 0;
				promoBarRef.current.style.visibility = 'hidden';
			}

			if (!hasScrolledPastThreshold && isPromoBarOpen) {
				promoBarRef.current.style.opacity = 1;
				promoBarRef.current.style.visibility = 'visible';
			}
		}
	}, [hasScrolledPastThreshold, isPromoBarOpen]);

	useEffect(() => {
		if (isMobile && hasScrolledPastThreshold) {
			setIsPreHeaderBarHidden(true);
		}

		if (isMobile && !hasScrolledPastThreshold) {
			setIsPreHeaderBarHidden(false);
		}
	}, [isMobile, hasScrolledPastThreshold]);

	const renderPreHeader = () => {
		if(!preHeaderBanner && !preHeaderBanner?.text) return null;

		const bannerContent = preHeaderBanner?.url ? (
			<Link to={preHeaderBanner.url}>
				{preHeaderBanner?.text}
			</Link>
		) :
		(
			<div>
				{preHeaderBanner?.text}
			</div>
		)

		return (
			<div className={`${ ns }__preheader-bar`} ref={preheaderRef} style={{backgroundColor: preHeaderBannerColor?.value}}>
				{bannerContent}
			</div>
		)
	}
	return (
		<div className={rootClassnames}>
			{renderPreHeader()}
			<Navigation pathname={pathname} isPreHeaderBarHidden={isPreHeaderBarHidden} hasPreHeader={!!preHeaderBanner?.text} />
			{promoBanner && promoBanner.text && promoBanner.url && (
				<>
					<Helmet>
						<body className={classNames({
							[`${ brand }`]: brand,
							[`${ ns }--has-promo-banner`]: true,
						})} />
					</Helmet>
					<div className={`${ ns }__promo-banner`} ref={promoBarRef}>
						<Link to={promoBanner.url}>
							{promoBanner.text}
						</Link>
						<button type={'button'} onClick={handleAnnouncementClose}>{renderIcon('close')}</button>
					</div>
				</>
			)}
		</div>
	);
};

export default Header;
