import * as React from 'react';

function Minus(props) {
	return (
		<svg
			width={'15'}
			height={'2'}
			viewBox={'0 0 15 2'}
			fill={'none'}
			xmlns={'http://www.w3.org/2000/svg'}
			{...props}
		>
			<path d={'M0 1h15'} stroke={'currentColor'} />
		</svg>
	);
}

export default Minus;
