import * as React from 'react';

function VideoPlay({
	brand,
	...props
}) {
	return (
		brand === 'babe-original' ? (
			<svg width={'80'} height={'80'} viewBox={'0 0 80 80'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
				<g opacity={'.7'}>
					<rect width={'80'} height={'80'} rx={'10'} fill={'#fff'} />
					<path d={'M57 39.5 29.25 52.9234V26.0766L57 39.5Z'} fill={'#000'} />
				</g>
			</svg>
		) : (
			<svg width={'92'} height={'92'} viewBox={'0 0 92 92'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
				<path fillRule={'evenodd'} clipRule={'evenodd'} d={'M46 92c25.4051 0 46-20.5949 46-46S71.4051 0 46 0 0 20.5949 0 46s20.5949 46 46 46ZM35.0121 64.6582l31.4333-18.1481-31.4333-18.148v36.2961Z'} fill={'#fff'} fillOpacity={'.5'} />
			</svg>
		)
	);
}

export default VideoPlay;
