import classNames from 'classnames';
import React, { useMemo } from 'react';
import uniqueId from 'uniqid';
import Button from '../Button';
import RichText from '../RichText/RichText';

const ns = `title`;

const Title = content => {
	const {
		eyebrow,
		title,
		description,
		textAlignment,
		textColor,
		cta,
		variant,
	} = content;
	const rootClassnames = classNames(`${ ns }`, {
		[`${ ns }--text-${ textAlignment }`]: textAlignment,
		[`${ ns }--text-color-${ textColor }`]: textColor,
		[`${ ns }--${ variant }`]: variant,
	});

	const noContent = useMemo(() => {
		const contentFields = [title, description, cta];
		return contentFields.every(field => { return !field; });
	}, [title, description, cta]);

	return !noContent &&	(
		<section className={rootClassnames}>
			<div className={`container-fluid`}>
				<div className={`${ ns }__content`}>
					{eyebrow && (
						<p className={`eyebrow ${ ns }__eyebrow`}>{eyebrow}</p>
					)}
					<div className={`${ ns }__main-content`}>
						{title && (
							<RichText fieldName={'title'} fieldData={title} />
						)}
						{description && (
							<RichText fieldName={'description'} fieldData={description} />
						)}
					</div>
					{cta && (
						<div className={`${ ns }__cta-container`}>
							<Button
								variant={cta.style}
								to={cta.link}
								key={`${ uniqueId('button') }`}
								withArrow={cta.withArrow}
							>
								{cta.text}
							</Button>
						</div>
					)}
				</div>

			</div>
		</section>
	);
};

export default Title;
