import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import renderIcon from '../../utils/renderIcon';

// Setup app element for react-modal
ReactModal.setAppElement('#___gatsby');

const ns = `site-modal`;

const Modal = ({
	children,
	variant,
	className,
	hasOverlay,
	bodyOpenClassName,
	shouldFocusAfterRender,
	contentLabel = '',
	isOpen = false,
	onRequestClose,
}) => {
	const [openState, setIsOpenState] = useState(true);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ variant }`]: variant,
		[`${ className }`]: className,
	});

	const handleCloseModal = () => {
		if (onRequestClose && typeof onRequestClose === 'function') {
			onRequestClose();
		} else {
			setIsOpenState(false);
		}
	};

	const handleOnAfterClose = () => {
		const bodyEl = document.body;
		bodyEl.style.paddingRight = `0px`;
	};

	const handleMenuOpen = () => {
		const bodyEl = document.body;
		const scrollbarWidth = (window.innerWidth - bodyEl.clientWidth);
		bodyEl.style.paddingRight = `${ scrollbarWidth }px`;
	};

	const overlayClassNames = classNames({
		[`${ ns }__overlay`]: true,
		[`${ ns }__overlay--${ variant }`]: variant,
		[`${ ns }__overlay--hidden`]: !hasOverlay,
	});

	useEffect(() => {
		if (isOpen) handleMenuOpen();

		setIsOpenState(isOpen);
	}, [isOpen]);

	return (
		<ReactModal
			contentLabel={contentLabel}
			className={rootClassnames}
			bodyOpenClassName={bodyOpenClassName ? `${ ns }--open ${ ns }--${ bodyOpenClassName }` : `${ ns }--open`}
			overlayClassName={overlayClassNames}
			isOpen={openState}
			shouldFocusAfterRender={shouldFocusAfterRender}
			onRequestClose={handleCloseModal}
			onAfterClose={handleOnAfterClose}
			closeTimeoutMS={400}
		>
			<div className={`${ ns }__content-inner`}>
				<button
					className={`${ ns }__close`}
					onClick={handleCloseModal}
				>
					{renderIcon('close')}
					<span className={`visually-hidden`}>Close</span>
				</button>
				{children}
			</div>
		</ReactModal>
	);
};

export default Modal;
