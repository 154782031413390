import * as React from 'react';

function ArrowInCircle({
	brand,
	...props
}) {
	return (
		brand === 'babe-original' ? (
			<svg
				xmlns={'http://www.w3.org/2000/svg'}
				fill={'none'}
				width={26}
				height={26}
				viewBox={'0 0 22 22'}
				{...props}
			>
				<path
					fill={'currentColor'}
					fillRule={'evenodd'}
					d={'M11.3926 22c5.799 0 10.5-4.9249 10.5-11s-4.701-11-10.5-11-10.5 4.9249-10.5 11 4.701 11 10.5 11Zm-2.5-6.7425V7l7 4.1287-7 4.1288Z'}
					clipRule={'evenodd'}
				/>
			</svg>
		) : (
			<svg
				xmlns={'http://www.w3.org/2000/svg'}
				fill={'none'}
				viewBox={'0 0 26 26'}
				width={26}
				height={26}
				{...props}
			>
				<path
					fill={'currentColor'}
					fillRule={'evenodd'}
					d={'M24.8926 12.9023c0 6.6035-5.1784 11.9023-11.5 11.9023-6.3217 0-11.5-5.2988-11.5-11.9023C1.8926 6.2987 7.0709 1 13.3926 1c6.3216 0 11.5 5.2987 11.5 11.9023Zm1 0c0 7.1257-5.5965 12.9023-12.5 12.9023-6.9036 0-12.5-5.7766-12.5-12.9023C.8926 5.7765 6.489 0 13.3926 0c6.9035 0 12.5 5.7766 12.5 12.9023Zm-8.2168.2069-7 4.1287V8.9805l7 4.1287Z'}
					clipRule={'evenodd'}
				/>
			</svg>
		)
	);
}

export default ArrowInCircle;
