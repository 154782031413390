import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { StoreProvider } from './src/context/store-context';
import { SearchProvider } from './src/context/search-context';
import i18next from './src/utils/i18next';

const brandClassName = process.env.GATSBY_PBG_BRAND || 'velour-beauty';

export const onRenderBody = ({ setBodyAttributes }) => {
	setBodyAttributes({
		className: brandClassName, // add brand class to body tag
	});
};

export const wrapRootElement = ({ element }) => {
	return (
		<StoreProvider>
			<SearchProvider>
				<I18nextProvider i18n={i18next}>
					{element}
				</I18nextProvider>
			</SearchProvider>
		</StoreProvider>
	);
};
