/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import uniqueId from 'uniqid';
import Link from '../Link';
import renderIcon from '../../utils/renderIcon';

const ns = `sub-menu`;

const SubMenu = ({
	menuLinks,
	hideSubMenu,
	isActive,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`active`]: isActive,
	});

	return (
		<div
			className={rootClassnames}
			onMouseLeave={hideSubMenu}
			onBlur={hideSubMenu}
		>
			{menuLinks?.map((menuLink, index) => {
				return (
					<React.Fragment key={uniqueId('pbg-')}>
						{menuLink.typename === 'ContentfulElementDropdownLink' && (
							<div className={`${ ns }__container ${ ns }__container--${ index }`}>
								<button type={'button'} onClick={hideSubMenu}>{renderIcon('close')}</button>
								{menuLink?.image && (
									<GatsbyImage alt={menuLink.contentTitle} image={menuLink?.image?.gatsbyImageData} />
								)}
								{menuLink.column1Links?.length > 0 && (
									<div className={`${ ns }__links`} key={uniqueId('pbg-')}>
										<p>{menuLink.column1Title}</p>
										<ul>
											{menuLink.column1Links.map(menuSubItem => {
												return (
													<li key={uniqueId('pbg-')}>
														<Link to={menuSubItem.url}>
															{menuSubItem.text}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								)}
								{menuLink.column2Links?.length > 0 && (
									<div className={`${ ns }__links`} key={uniqueId('pbg-')}>
										<p>{menuLink.column2Title}</p>
										<ul>
											{menuLink.column2Links.map(menuSubItem => {
												return (
													<li key={uniqueId('pbg-')}>
														<Link to={menuSubItem.url}>
															{menuSubItem.text}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								)}
								{menuLink.column3Links?.length > 0 && (
									<div className={`${ ns }__links`} key={uniqueId('pbg-')}>
										<p>{menuLink.column3Title}</p>
										<ul>
											{menuLink.column3Links.map(menuSubItem => {
												return (
													<li key={uniqueId('pbg-')}>
														<Link to={menuSubItem.url}>
															{menuSubItem.text}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								)}
								{menuLink.column4Links?.length > 0 && (
									<div className={`${ ns }__links`} key={uniqueId('pbg-')}>
										<p>{menuLink.column4Title}</p>
										<ul>
											{menuLink.column4Links.map(menuSubItem => {
												return (
													<li key={uniqueId('pbg-')}>
														<Link to={menuSubItem.url}>
															{menuSubItem.text}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								)}
							</div>
						)}
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default SubMenu;
