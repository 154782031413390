/* eslint-disable */

import * as React from 'react';
import Client from 'shopify-storefront-client';
import { decodeBase64, learnqPush, dataLayerPush } from '../utils';
import { addKlaviyoProfileIdToUrl } from '../utils/klaviyo';
import * as videeo from '../utils/videeo';
import * as northbeam from '../utils/northbeam';

const client = Client.fromSettings({
	shop: { myshopify_domain: process.env.GATSBY_SHOPIFY_STORE_URL },
	api: {
		token: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
		version: process.env.GATSBY_SHOPIFY_API_VERSION || '2023-07',
	},
});

const defaultValues = {
	// cart: [],
	isCartFlyoutOpen: false,
	cartFlyoutOpen: () => {},
	cartFlyoutClose: () => {},
	// isOpen: false,
	loading: false,
	onOpen: () => {},
	onClose: () => {},
	addVariantsToCart: () => {},
	removeLineItem: () => {},
	updateLineItem: () => {},
	goToCheckout: () => {},
	client,
	checkout: {
		lineItems: [],
	},
	cart: {
		lineItems: [],
	},
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;
const localStorageCartKey = 'shopify_cart_id';

export const StoreProvider = ({ children }) => {
	const [cart, setCart] = React.useState(defaultValues.cart);
	const [loading, setLoading] = React.useState(false);
	const cartTimeoutRef = React.useRef(null);
	const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
	const [isCartFlyoutOpen, setIsCartFlyoutOpen] = React.useState(false);
	const [cartLineItemsUpdatedAt, setCartLineItemsUpdatedAt] = React.useState(
		Date.now()
	);

	const setCartItem = (cart) => {
		if (isBrowser) {
			const cartGuid = cart.id;
			const cartId = cartGuid.replace('gid://shopify/Cart/', '');

			localStorage.setItem(localStorageCartKey, cartGuid);

			// Set the cart cookie, so it's available in the Shopify storefront.
			const domain = window.location.hostname.replace('www.', '');
			document.cookie = `cart=${cartId}; path=/; domain=${domain};`;
		}

		setCart(cart);
	};

	React.useEffect(() => {
		const initializeCart = async () => {
			const existingCartID = isBrowser
				? localStorage.getItem(localStorageCartKey)
				: null;

			if (
				existingCartID &&
				existingCartID !== 'null' &&
				existingCartID !== 'undefined'
			) {
				try {
					const existingCart = await client.Cart.fetch(existingCartID);
					setCartItem(existingCart);
					return existingCart;
				} catch (error) {
					console.error(error);
					localStorage.setItem(localStorageCartKey, null);

					// Remove the cart cookie.
					const domain = window.location.hostname.replace('www.', '');
					document.cookie = `cart=; path=/; domain=${domain}; max-age=-99999999;`;
				}
			}

			const newCart = await client.Cart.create();
			setCartItem(newCart);
			return newCart;
		};

		initializeCart().then((cart) => {
			//	------------------------------------------------------
			//	Allow passing of ?discount_code into checkout from url
			//	------------------------------------------------------
			const params = new URLSearchParams(window.location.search);
			const discountCode = params.get('discount_code');

			if (discountCode) {
				client.Cart.updateDiscountCodes(cart.id, [discountCode]).then(
					(cart) => {
						const upcaseDiscountCode = discountCode.toUpperCase();
						const isDiscountApplied = cart.discountCodes.find(
							({ code = '' }) => code.toUpperCase() === upcaseDiscountCode
						);
						if (isDiscountApplied) {
							console.log(
								`Discount code added to cart: '${upcaseDiscountCode}'`
							);
						} else {
							console.log(
								`Failed to add discount code to cart: '${upcaseDiscountCode}'`
							);
						}
					}
				);
			}
		}, []);
	}, []);

	const doTimeoutCart = (argumentPassthrough) => {
		setDidJustAddToCart(true);

		let timeoutRef = cartTimeoutRef.current;

		//	If we're already in timeout
		if (timeoutRef) {
			clearTimeout(timeoutRef);
		}
		//	Set a new timeout; kick the can down the road.
		cartTimeoutRef.current = setTimeout(() => {
			return setDidJustAddToCart(false);
		}, 3000);

		return argumentPassthrough;
	};

	const goToCheckout = () => {
		const checkoutUrlWithKlaviyoId = addKlaviyoProfileIdToUrl(cart.checkoutUrl);
		window.open(checkoutUrlWithKlaviyoId, '_self');
		dataLayerPush().beginCheckout({
			items: cart.lineItems.map((item) => {
				return {
					quantity: item.quantity,
					product: item.variant.product,
					variant: {
						...item.variant,
						price: item.variant?.price?.amount,
					},
				};
			}),
		});
	};

	const updateCartAttributes = (attributes = []) => {
		setLoading(true);
		const cartID = cart.id;

		return client.Cart.updateAttributes(cartID, attributes)
			.then(doTimeoutCart)
			.then((res) => {
				setCart(res);
				setLoading(false);
			});
	};

	const addVariantsToCart = (items = []) => {
		setLoading(true);

		const cartID = cart.id;

		const lineItemsToUpdate = items.map(
			({ variantId, quantity, attributes = [], sellingPlanId = null }) => {
				variantId = decodeBase64(variantId);
				return {
					merchandiseId: variantId,
					quantity: parseInt(quantity, 10),
					attributes,
					sellingPlanId,
				};
			}
		);

		return client.Cart.LineItems.add(cartID, lineItemsToUpdate)
			.then(doTimeoutCart)
			.then((res) => {
				const { lineItems } = res;
				videeo.addToCartEvent(lineItems, lineItemsToUpdate);
				northbeam.addToCartEvent(lineItems, lineItemsToUpdate);
				learnqPush().addToCart(res);
				setCart(res);
				setCartLineItemsUpdatedAt(Date.now());
				setLoading(false);
			});
	};

	const removeLineItem = (cartID, lineItemIDs = []) => {
		setLoading(true);

		return client.Cart.LineItems.remove(cartID, lineItemIDs)
			.then(doTimeoutCart)
			.then((res) => {
				setCart(res);
				setCartLineItemsUpdatedAt(Date.now());
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				alert('There was an error, please contact us to complete your order.');
				setLoading(false);
			});
	};

	const updateLineItem = (cartID, items = []) => {
		setLoading(true);

		const lineItemsToUpdate = items.map(({ id, quantity, attributes }) => {
			return {
				id,
				quantity: parseInt(quantity, 10),
				attributes,
			};
		});

		return client.Cart.LineItems.update(cartID, lineItemsToUpdate)
			.then(doTimeoutCart)
			.then((res) => {
				setCart(res);
				setCartLineItemsUpdatedAt(Date.now());
				setLoading(false);
			});
	};

	const cartFlyoutOpen = () => {
		if (!isCartFlyoutOpen) setIsCartFlyoutOpen(true);
	};

	const cartFlyoutClose = () => {
		setIsCartFlyoutOpen(false);
	};

	React.useEffect(() => {
		const addSDKPlugins = () => {
			const plugins = {
				addToCart: async (params) => {
					try {
						const { shopifyInventoryId, quantity, videeoTrackingEventId } =
							params;
						const variantId =
							'gid://shopify/ProductVariant/' + shopifyInventoryId;
						const eventAttr = 'event-id-' + videeoTrackingEventId;
						const attributes = [
							{ key: '_vfs_sourced', value: 'true' },
							{ key: '_vfs_event_id', value: String(videeoTrackingEventId) },
						];
						const variant = { variantId, quantity, attributes };
						const cartAttributes = [
							...cart.attributes,
							{ key: eventAttr, value: '1' },
						];
						await addVariantsToCart([variant]);
						await updateCartAttributes(cartAttributes);
					} catch (error) {
						console.error(error);
						throw { description: error.message };
					}
				},
				goToCart: goToCheckout,
			};

			window.videeoSDK.addPlugins(plugins);
		};

		if (typeof window !== 'undefined' && window.videeoSDK) {
			addSDKPlugins();
		}
	}, [typeof window !== 'undefined' && window.videeoSDK, JSON.stringify(cart)]);

	return (
		<StoreContext.Provider
			value={{
				...defaultValues,
				addVariantsToCart,
				isCartFlyoutOpen,
				cartFlyoutOpen,
				cartFlyoutClose,
				removeLineItem,
				updateLineItem,
				cart,
				cartLineItemsUpdatedAt,
				loading,
				didJustAddToCart,
				goToCheckout,
			}}
		>
			{children}
		</StoreContext.Provider>
	);
};
