/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
import renderIcon from '../../utils/renderIcon';
import {
	Accordion,
	AccordionControl,
	AccordionItem,
	AccordionPanel
} from '../Accordion';

import Link from '../Link';
import Modal from '../Modal';

const ns = `navigation-mobile-menu`;

const NavMobileMenu = ({
	isMenuOpen, toggleMobileMenu, menuLinksMobile, isPreHeaderBarHidden,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--preheader-bar-hidden`]: isPreHeaderBarHidden,
	});

	return (
		<Modal
			className={rootClassnames}
			variant={'mobile-menu'}
			onRequestClose={() => { toggleMobileMenu(); }}
			isOpen={isMenuOpen}
		>
			<div className={`${ ns }__mobile-menu-container`}>
				{menuLinksMobile?.map(menuLinkMobile => {
					const mergeColumnLinks = [
						...(menuLinkMobile.column1Links || []),
						...(menuLinkMobile.column2Links || []),
						...(menuLinkMobile.column3Links || []),
						...(menuLinkMobile.column4Links || []),
					];
					return (
					// eslint-disable-next-line react/jsx-no-useless-fragment
						<React.Fragment key={menuLinkMobile.contentTitle || menuLinkMobile.column1Title || menuLinkMobile.text}>
							{menuLinkMobile.typename === 'ContentfulElementDropdownLink'
								? (
									<Accordion>
										<AccordionItem>
											<AccordionControl>
												<p className={`${ ns }__menu-title`}>{menuLinkMobile.column1Title}</p>
												<span>{renderIcon('cheveron-down')}</span>
											</AccordionControl>
											<AccordionPanel>
												<div className={`${ ns }__menu-mobile-links`}>
													<ul>
														{mergeColumnLinks.map(menuSubItem => {
															return (
																<li key={menuSubItem.text}>
																	<Link to={menuSubItem.url}>
																		{menuSubItem.text}
																	</Link>
																</li>
															);
														})}
													</ul>
												</div>
											</AccordionPanel>
										</AccordionItem>
									</Accordion>
								)
								: (
									<Link to={menuLinkMobile.url}>
										{menuLinkMobile.text}
									</Link>
								)}
						</React.Fragment>

					);
				})}
				<Link to={`https://${ process.env.GATSBY_SHOPIFY_STORE_URL }/account`}>
					{renderIcon('account')}
					LOGIN
				</Link>
			</div>
		</Modal>
	);
};

export default NavMobileMenu;
