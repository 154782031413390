import * as React from 'react';

function Arrow(props) {
	return (
		<svg
			width={`16`}
			height={`12`}
			viewBox={`0 0 16 12`}
			fill={`none`}
			xmlns={`http://www.w3.org/2000/svg`}
			{...props}
		>
			<path
				d={`m13 4.5 1.363 1.498L13 7.5v-3Z`}
				fill={`currentColor`}
			/>
			<path
				d={`M0 5.998h14.363m0 0L13 4.5m1.363 1.498L13 7.5M9.473.5 13 4.5m-3.527 7L13 7.5m0-3v3`}
				stroke={`currentColor`}
			/>
		</svg>
	);
}

export default Arrow;
