import React, { useContext } from 'react';
import classNames from 'classnames';

import { StoreContext } from '../../../context/store-context';
import { SearchContext } from '../../../context/search-context';
import renderIcon from '../../../utils/renderIcon';

const ns = `cart-button`;

const CartButton = () => {
	const {
		cart,
		isCartFlyoutOpen,
		cartFlyoutOpen,
		cartFlyoutClose,
	} = useContext(StoreContext);
	const { handleSearchClose } = useContext(SearchContext);

	const items = cart ? cart.lineItems : [];

	const quantity = items.reduce((total, item) => {
		return total + item.quantity;
	}, 0);

	const handleCartClick = (event) => {
		event.preventDefault();
		handleSearchClose();
		isCartFlyoutOpen ? cartFlyoutClose() : cartFlyoutOpen();
		return false
	};

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const cartUrl = `https://${ process.env.GATSBY_SHOPIFY_STORE_URL }/cart`;

	return (
		<a
			href={cartUrl}
			aria-label={`Shopping Cart with ${ quantity } items`}
			onClick={handleCartClick}
			className={rootClassnames}
		>
			<span aria-hidden>{renderIcon('shopping-bag')}</span>
			{quantity > 0 && <span className={`${ ns }__quantity`}>{quantity}</span>}
		</a>
	);
};

export default CartButton;
