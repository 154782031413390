import * as React from 'react';

function ArrowInCircle({
	brand,
	...props
}) {
	return (
		brand === 'babe-original' ? (
			<svg width={'20'} height={'20'} viewBox={'0 0 20 20'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
				<circle cx={'10'} cy={'10'} r={'10'} fill={'#DEDEDE'} />
				<path d={'M9.15168 7.61053V15h1.80472V7.61053H9.15168Zm-.05684-2.88474v1.83316h1.91846V4.72579H9.09484Z'} fill={'#000'} />
			</svg>
		) : (
			<svg width={'20'} height={'20'} viewBox={'0 0 20 20'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
				<path d={'M11.1749 4.696c0-.546-.434-.994-.994-.994-.56001 0-.99401.462-.99401.994s.434.994.99401.994c.56 0 .994-.462.994-.994ZM9.52289 15h1.31601V7.328H9.52289V15Z'} fill={'currentColor'} />
				<circle cx={'10'} cy={'10'} r={'9.5'} stroke={'currentColor'} />
			</svg>
		)
	);
}

export default ArrowInCircle;
