/* eslint-disable */

import { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import debounce from 'lodash.debounce';
import { StoreContext } from '../context/store-context';
import * as rebuy from './rebuy';

/**
 * Gets Collection from Shopify by its handle
 * @param {string} Shopify Handle
 */

 export function useGetCollectionByHandle(handle) {
	if (!handle) return;

	const [result] = useQuery({
		query: CollectionByHandleQuery,
		variables: {
			handle,
		}
	});

	return {
		data: result.data,
	};
}

export const useWindowEventListener = (windowEvent, handlerCallback, useCapture = false) => {
	useEffect(() => {
		const isBrowser = typeof window !== 'undefined';

		if (isBrowser) {
			window.addEventListener(windowEvent, handlerCallback, useCapture);
		}

		return () => {
			window.removeEventListener(windowEvent, handlerCallback, useCapture);
		};
	}, [windowEvent, handlerCallback, useCapture]);
};

export const useAddKeyProp = (nonGuidItems, prefix) => {
	const [keyedItems, setKeyedItems] = useState();

	useEffect(() => {
		nonGuidItems
		&& nonGuidItems.length > 0
		&& setKeyedItems((
			nonGuidItems.map(item => {
				return {
					key: uniqueId(prefix),
					...item,
				};
			})
		));
	}, [nonGuidItems, prefix]);

	return keyedItems;
};

export const useKeypressHandler = (key, action) => {
	useEffect(() => {
		function onKeyup(e) {
			if (e.code === key && typeof action === 'function') action(e);
		}
		window.addEventListener('keyup', onKeyup);

		return () => {
			return window.removeEventListener('keyup', onKeyup);
		};
	}, [key, action]);
};

/**
 *
 * @param {string} query Media query string that needs to be checked
 * @returns a boolean indicating whether the media query passed is matching device
 */
export const useMediaQuery = (query) => {
	/**
	 * Replacing all double quotes here. We are doing it because
	 * currently we export our breakpoints from the scss file
	 * which wraps the value in a double quote
	 */
	let mediaQuery = query.replace(/["]+/g, '')

	/**
	 * If user forgot to supply parenthesis, wrap the query string with them
	 */
	if(!mediaQuery.startsWith('(')){
		mediaQuery = `(${mediaQuery})`
	}

	/**
	 * Internal function , which uses matchMedia API to check the validity
	 * of a media query
	 * More about matchMedia here :
	 * https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
	 */
	const getMatches = (query) => {
		// Prevents SSR issues
		if (typeof window !== 'undefined') {
		  return window.matchMedia(query).matches
		}
		return false
	  }
	  const [matches, setMatches] = useState(getMatches(mediaQuery))

	  /**
	   * Set the value of matches again if matchMedia changes
	   */
	  function handleChange() {
		setMatches(getMatches(mediaQuery))
	  }

	  useEffect(() => {

		/**SSR check */
		if (typeof window === 'undefined') {
			return;
		}

		const matchMedia = window.matchMedia(mediaQuery)

		// Triggered at the first client-side load and if mediaQuery changes
		handleChange()

		// Add listener for when matchMedia changes
		matchMedia.addEventListener('change', handleChange)

		return () => {
			matchMedia.removeEventListener('change', handleChange)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [mediaQuery])
	return matches
}

export const useWindowSize = () => {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		// Add event listener
		window.addEventListener('resize', debounce(handleResize, 100));

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount
	return windowSize;
}

/**
 ** useIsMobileAgent()
 * 
 * Checks the window's user agent
 *
 * @returns true if it is a mobile device or a device in responsive mode.
 */
export const useIsMobileAgent = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

/**
 ** useGetMediaSize(config)
 * 
 * Hook to get the size of the width of the window. Adds a resize event listener
 * with an option to disable it.
 * 
 * @param config | Object with optional configuration.
 * @param config.disableResize | disable the resize event
 * @returns the size of media
 */
export const useGetMediaSize = (config) => {
	const [mediaSize, setMediaSize] = useState({
		isMini: false,
		isMobile: false,
		isMobileAgent: false,
		isTablet: false,
		isLaptop: false,
		isDesktop: false,
		isWidescreen: false
	});

	useEffect(() => {
		function handleResize() {
			setMediaSize({
				isMini: window.innerWidth < 475,
				isMobile: window.innerWidth < 768,
				isMobileAgent: window.innerWidth < 768 || useIsMobileAgent(),
				isTablet: window.innerWidth >= 768 && window.innerWidth < 992,
				isLaptop: window.innerWidth >= 992 && window.innerWidth < 1200,
				isDesktop: window.innerWidth >=1200 && window.innerWidth < 1400,
				isWidescreen: window.innerWidth >= 1400
			});
		}

		if (!config?.disableResize) {
			window.addEventListener('resize', debounce(handleResize, 1000));
		}

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return mediaSize;
}

/**
 * A function to handle a affiliate links. The function will check
 * if the window location value affiliate is present and if it is,
 * it will cross reference that value in WP to check if it's valid.
 * If valid it will apply the discount to the checkoutId
 * @param {affiliateCodeURL} string
 * @param {affiliateCodesWP} array
 */

export const affiliateLink = (affiliateCodeURL, affiliateCodesWP) => {
	const {
		cart,
		applyDiscount
	} = useContext(StoreContext);

	if (affiliateCodeURL) {
		const params = {};
		affiliateCodeURL.slice(1).split('&').forEach(code => {
			if (code === '') return;
			const split = code.split('=');
			// note: if two valid affiliate codes exist in the URL,
			// only the last valid affiliate code will be applied
			params[split[0]] = (split.length >= 2 ? split[1] : true);
		});

		if (params.affiliate && affiliateCodesWP.length > 0) {
			affiliateCodesWP.forEach(code => {
				if (params.affiliate === code.affiliateCode) {
					applyDiscount(cart.id, code.shopifyDiscountCode)
				}
			});
		}
	}
};

export const useIsScrollPositionPassThreshold = (threshold) => {
	const [hasScrolledPastThreshold, setHasScrolledPastThreshold] = useState(false)

	useEffect(() => {
		const scrolledMoreThanThreshold = (currentScrollYPosition) => {
			return Math.abs(currentScrollYPosition) > threshold;
		}

		const updateScrollPosition = () => {
			if (scrolledMoreThanThreshold(window.scrollY)) {
				setHasScrolledPastThreshold(true);
			} else {
				setHasScrolledPastThreshold(false);
			}
		}

		const onScroll = () => window.requestAnimationFrame(updateScrollPosition);

		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll)
		}
	}, [])

	return hasScrolledPastThreshold
}

export const useIsClient = ()=>{
	const [isClient,setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	return isClient
}

/**
 *
 * @param {object} [params]
 * @param {string[]|string} [params.shopifyProductIds]
 * @param {string[]|string} [params.shopifyVariantIds]
 * @param {string} [params.shopifyCollectionId]
 * @param {string|number} [params.limit]
 * @param {('yes'|'no')} [params.bustCache]
 * @param {('yes'|'no')} [params.filterOOS]
 * @param {('default'|'similar')} [params.context]
 */
export const useProductRecommendations = (params, canUseCustomDataSource) => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const data = canUseCustomDataSource
					? await rebuy.getProductsFromCustomDataSource(params)
					: await rebuy.getRecommendedProducts(params);
				setData(data);
				setError(null);
			} catch (error) {
				console.error(error);
				setData(null);
				setError(error);
			}

			setIsLoading(false);
		})();
	}, [JSON.stringify(params)]);

	const calculateValue = () => {
		return Object.create({ isLoading, data, error }, {
			isEmpty: {
				get() {
					return !data?.length;
				}
			},
		});
	};

	return calculateValue();
}
