/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-throw-literal */
/* eslint-disable prefer-template */

import snakeCase from 'lodash.snakecase';
import camelCase from 'lodash.camelcase';
import { REBUY_API_KEY, REBUY_CUSTOM_DATA_SOURCE_ID } from './config';

const BASE_URL = 'https://rebuyengine.com';

function camelCaseObjectKeys(o) {
  const isPlainObject = u => {
    return !!u && typeof u === 'object';
  };

  if (!isPlainObject(o)) {
    return o;
  }

  const base = Array.isArray(o) ? [] : {};

  const ret = Object.entries(o).reduce((acc, cur) => {
    const [k, v] = cur;
    const key = camelCase(k);
    const value = isPlainObject(v) ? camelCaseObjectKeys(v) : v;
    acc[key] = value;

    return acc;
  }, base);

  return ret;
}

async function callAPI(options) {
  if (!REBUY_API_KEY) {
    throw new Error('Rebuy API key missing');
  }

  const {
    method = 'GET',
    endpoint,
    headers,
    params,
    payload,
    ...rest
  } = options;

  const url = new URL(endpoint, BASE_URL);
  const searchParams = new URLSearchParams(url.search);

  Object.entries(params || {}).forEach(param => {
    const [k, v] = param;

    if (v) {
      const key = snakeCase(k);
      const value = Array.isArray(v) ? v.join(',') : v;
      searchParams.append(key, value);
    }
  });

  searchParams.append('key', REBUY_API_KEY);

  const absoluteURI = url + '?' + searchParams;
  const fetchOptions = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(payload),
    ...rest,
  };

  const response = await fetch(absoluteURI, fetchOptions);
  const { error, data = {}} = await response.json();

  if (error || !response.ok) {
    throw {
      response,
      message: error?.error || response.statusText,
      status: response.status,
      statusText: response.statusText,
      ...(!!error && { error }),
    }
  }

  return camelCaseObjectKeys(data);
}

/**
 *
 * @param {object} [params]
 * @param {string[]|string} [params.shopifyProductIds]
 * @param {string[]|string} [params.shopifyVariantIds]
 * @param {string} [params.shopifyCollectionId]
 * @param {string|number} [params.limit]
 * @param {('yes'|'no')} [params.bustCache]
 * @param {('yes'|'no')} [params.filterOOS]
 * @param {('default'|'similar')} [params.context]
 * @returns {Promise<object>}
 */
export async function getRecommendedProducts(params) {
  const endpoint = '/api/v1/products/recommended';

  params.shopifyProductIds = Array.isArray(params.shopifyProductIds)
    ? params.shopifyProductIds.map(id => id.split('/').pop())
    : params.shopifyProductIds?.split('/').pop();

  params.shopifyVariantIds = Array.isArray(params.shopifyVariantIds)
    ? params.shopifyVariantIds.map(id => id.split('/').pop())
    : params.shopifyVariantIds?.split('/').pop();

  params.shopifyCollectionId = params.shopifyCollectionId?.split('/').pop();
  params.filterOOS = params.filterOOS || 'yes';
  params.bustCache = params.bustCache || 'no';
  params.context = params.context || 'default';
  return callAPI({ endpoint, params })
}

/**
 *
 * @param {object} [params]
 * @param {string[]|string} [params.shopifyProductIds]
 * @param {string[]|string} [params.shopifyVariantIds]
 * @param {string} [params.shopifyCollectionId]
 * @param {string|number} [params.limit]
 * @param {string} [params.cartSubtotal]
 * @param {string|number} [params.cartLineCount]
 * @param {string|number} [params.cartItemCount]
 * @param {number} [params.time]
 * @returns {Promise<object>}
 */
export async function getProductsFromCustomDataSource(params) {
  if (!REBUY_CUSTOM_DATA_SOURCE_ID) {
    throw new Error('Rebuy custom data source ID missing');
  }

  const endpoint = `/api/v1/custom/id/${REBUY_CUSTOM_DATA_SOURCE_ID}`;
  
  params.shopifyProductIds = Array.isArray(params.shopifyProductIds)
  ? params.shopifyProductIds.map(id => id.split('/').pop())
  : params.shopifyProductIds?.split('/').pop();

  params.shopifyVariantIds = Array.isArray(params.shopifyVariantIds)
  ? params.shopifyVariantIds.map(id => id.split('/').pop())
  : params.shopifyVariantIds?.split('/').pop();

  params.time = Date.now();

  return callAPI({ endpoint, params })
}
