/*	eslint-disable arrow-body-style */
import React from 'react';

//	Notes:
//	-	The className of `ot-sdk-show-settings` is all that the OneTrust JavaScript SDK looks for when
//		it loads.
//		That SDK will automatically attach the needed onclick event to this element which will show
//		the popup preferences modal.
//		The SDK will also change the innerHTML of the button to align with the settings inside the
//		"OneTrust Web-Admin Tool". So don't be surprised when the text changes before your eyes.
export const ButtonToTriggerPopup = () => {
	return (
		<button className="ot-sdk-show-settings" type="button">
			Do Not Sell or Share My Personal Information
		</button>
	)
};
